// React Dependencies
import { useState, React, useEffect } from 'react';
// Common Dependencies
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loggedUser } from '../../../store/features/authSlice';
// Component
import { Form, Card, Row, Col, Badge } from 'react-bootstrap';
import { TNButton } from '@common/components';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
import Select from 'react-select';
// Validation
import { useFormik } from 'formik';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFloppyDisk, faTrash, faXmark, faClose } from '@fortawesome/free-solid-svg-icons';
import 'quill-better-table/src/assets/quill-better-table.scss'; // Import the Better Table styles
import $ from 'jquery';

// Helper
import { defaultValue } from '@helpers';

// API
import {
  useRouteAccessList,
  useGetTaskDetails,
  useGetTaskPriorities,
  useGetAssignedProjectEmployeeList,
  useUpdateAssignees,
  useEditTask,
  useDeleteTask
} from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Asset
import '@assets/scss/page/_taskView.scss';
// Image Popup
import { Lightbox } from 'react-modal-image';

import ListWorkLogPage from './WorkLog/ListWorkLogPage';
import ListHistoryPage from './History/ListHistoryPage';
import ListCommentPage from './Comments/ListCommentPage';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';

const ViewTaskPage = ({ t, socket }) => {
  // Auth Data
  const checkLoggedInUser = useSelector(loggedUser);
  // Navigate
  const navigate = useNavigate();
  // Project ID And Task ID
  let { id, projectTaskId } = useParams();
  // Get the value from parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const viewType = queryParams.get('viewType') ?? 2;
  // States
  const [taskDetails, setTaskDetails] = useState(null);
  const [routeName, setRouteNames] = useState([]);
  const [showAssigneeDropDown, setShowAssigneeDropDown] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [projectTeamMembers, setProjectTeamMembers] = useState([]);

  // Comment, Work Log And History Tab
  const [userData, setUserData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [popupImage, setPopupImage] = useState(null);
  // State For Work Log Add Edit Popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);
  // Dropdown Lists

  // Task Priorities List
  const { data: taskPriorityList, isLoading: isTaskPriorityLoading } = useGetTaskPriorities();
  let options2 = [];
  if (taskPriorityList !== undefined && !isTaskPriorityLoading) {
    Object.entries(taskPriorityList).map(([value, label]) => {
      options2.push({
        value: parseInt(value),
        label: label
      });
      return true;
    });
  }
  // Get Employee List
  const { data: employeeList, isLoading: isUserLoading } = useGetAssignedProjectEmployeeList(id);
  useEffect(() => {
    setEmployeeOptions(employeeList?.data?.employee_list ?? []);
    let tmpprojectMembers = [];
    employeeList?.data?.employee_list.map((val) => {
      tmpprojectMembers.push({
        id: val.user_id,
        value: val.full_name,
        first_name: val.first_name,
        last_name: val.last_name,
        profile_image: val.profile_image
      });
      return true;
    });
    setProjectTeamMembers(tmpprojectMembers);
  }, [employeeList]);

  // Task Details Fetch
  useGetTaskDetails(
    id,
    projectTaskId,
    ({ data: taskData }) => {
      if (taskData) {
        setTaskDetails(taskData);
      }
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  // Edit And List Handlers
  const handleEditClick = (id, taskId) => {
    navigate(`/projects/view/${id}/task/${taskId}/edit?viewType=${viewType}`);
  };
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };

  // Remove Task Handler
  const { deleteTask } = useDeleteTask();
  const handleDeleteClick = (id, taskStatusTypeId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_task_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTask(id, taskStatusTypeId);
                    if (response && response.message) {
                      toast.success(response.message);
                      socket.emit('task-updated', response?.data);
                    }
                    onClose();
                    navigate(`/projects/view/${id}/task?viewType=${viewType}`);
                  } catch (error) {
                    toast.error(error.message);
                    onClose();
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };

  // Update Task API
  const { mutate: updateTaskAssignees } = useUpdateAssignees((response) => {
    toast.success(response.message);

    let oldTaskDetails = taskDetails.assignee;
    if (typeof oldTaskDetails !== 'object' && oldTaskDetails.trim() == '') {
      oldTaskDetails = {};
    }
    oldTaskDetails.full_name = formik.values?.assigned_user_id?.label;
    oldTaskDetails.user_status = formik.values?.assigned_user_id?.user_status;

    setTaskDetails({
      ...taskDetails,
      assignee: oldTaskDetails
    });
    setShowAssigneeDropDown(!showAssigneeDropDown);
  });
  // Task Data Initialize On Load
  useEditTask(
    id,
    projectTaskId,
    ({ data: taskData }) => {
      if (taskData) {
        if (taskData.assignee) {
          formik.values.assigned_user_id = {
            value: taskData.assignee.user_id,
            label: taskData.assignee.full_name
          };
          return formik.values.assigned_user_id;
        }
      }
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  // Form Validation And Submit
  const formik = useFormik({
    initialValues: {
      project_id: id,
      id: projectTaskId,
      assigned_user_id: {
        value: taskDetails?.assignee.user_id,
        label: taskDetails?.assignee?.full_name,
        user_status: taskDetails?.assignee?.user_status
      }
    },
    onSubmit: (values) => {
      updateTaskAssignees(values);
    }
  });

  // Profile Image
  const getProfileImageURL = (userId, profileImage) => {
    if (profileImage) {
      let imageURL = `${process.env.MIX_AWS_URL}user_profile/${userId}/${profileImage}`;
      return imageURL;
    } else {
      return false;
    }
  };
  const getImageName = (firstName, lastName) => {
    let imageName = 'US';
    if (firstName) {
      imageName = firstName.substring(0, 1);
    }
    if (lastName) {
      imageName += lastName.substring(0, 1);
    }
    return imageName;
  };

  // Set Logged In Custom User Data
  useEffect(() => {
    if (checkLoggedInUser && checkLoggedInUser?.user) {
      let userInfo = checkLoggedInUser?.user;
      userInfo = JSON.parse(JSON.stringify(userInfo));
      userInfo['full_name'] = userInfo?.first_name + ' ' + userInfo?.last_name;
      userInfo['image_name'] = getImageName(userInfo?.first_name, userInfo?.last_name);
      setUserData(userInfo);
    }
  }, [checkLoggedInUser]);

  useEffect(() => {
    // Use jQuery to add a click event to all img tags after the component is mounted
    $(document).on('click', 'img', function (event) {
      const imageSrc = $(event.target).attr('src');
      setPopupImage(imageSrc);
    });

    // Cleanup jQuery event listener when the component is unmounted
    return () => {
      $(document).off('click', 'img'); // Remove event listeners on unmount
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)

  return (
    <>
      <CommonSEOTitle title={t('page.project_task_view_title')} />
      <ProjectNavigation t={t} id={id} activeLabel="Tasks" />
      {taskDetails && (
        <Card className="inner-box">
          <div>
            <Row>
              <Col lg={12} xs={12} className="taskViewDetailsDiv">
                <Row>
                  <Col lg={11} xs={11}>
                    <h4>{taskDetails.name}</h4>
                  </Col>
                  <Col lg={1} xs={1}>
                    {shouldShowRoute('projects.task.destroy') && (
                      <FontAwesomeIcon
                        style={{
                          float: 'right',
                          marginLeft: '10px',
                          fontSize: '20px',
                          color: '#dc3545'
                        }}
                        className="alert-close "
                        icon={faTrash}
                        onClick={() => {
                          handleDeleteClick(id, projectTaskId);
                        }}
                      />
                    )}
                    {shouldShowRoute('projects.task.edit') && (
                      <FontAwesomeIcon
                        style={{ float: 'right', fontSize: '20px' }}
                        className="alert-close"
                        icon={faEdit}
                        onClick={() => {
                          handleEditClick(id, projectTaskId);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <h5>
                      <Badge key={'sprint'} className="sprintInfo">
                        {taskDetails?.sprint_name ?? ''}
                      </Badge>
                    </h5>
                  </Col>
                  <Col lg={8} xs={12}>
                    <Row>
                      <Col lg={11} xs={11}>
                        <div style={{ float: 'right' }}>
                          <h5>
                            <Badge
                              key={'priority'}
                              className={
                                taskDetails?.priority === 3
                                  ? 'highPriority'
                                  : taskDetails?.priority === 2
                                    ? 'mediumPriortity'
                                    : 'lowPriortity'
                              }>
                              {options2
                                ? options2
                                    .filter(function (obj) {
                                      return obj.value === taskDetails?.priority;
                                    })
                                    .map(function (obj) {
                                      return obj.label;
                                    })[0]
                                : ''}
                            </Badge>
                            {' | '}
                            <Badge key={'task_status_info'} className="taskStatusInfo">
                              {taskDetails?.task_status ?? ''}
                            </Badge>
                            {taskDetails?.assignee ? (
                              <div style={{ float: 'right' }} className="assignees_badges_div">
                                {' | '}
                                <Badge
                                  className="assigneesBadge"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      taskDetails?.assignee?.user_status === 0
                                        ? `${taskDetails?.assignee.full_name} <span style="color: red">[ Inactive ]</span>`
                                        : taskDetails?.assignee.full_name
                                  }}></Badge>
                              </div>
                            ) : (
                              ''
                            )}
                          </h5>
                        </div>
                      </Col>
                      <Col lg={1} xs={1}>
                        {shouldShowRoute('projects.task.update-assignee') && (
                          <FontAwesomeIcon
                            style={{ float: 'right', cursor: 'pointer' }}
                            icon={!showAssigneeDropDown ? faEdit : faXmark}
                            onClick={() => {
                              setShowAssigneeDropDown(!showAssigneeDropDown);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {showAssigneeDropDown && shouldShowRoute('projects.task.update-assignee') && (
                  <>
                    <Row>
                      <Col lg={12} xs={12}>
                        <Form onSubmit={formik.handleSubmit}>
                          <Row>
                            <Col lg={11} xs={11}>
                              <div style={{ float: 'right', marginRight: '-38px' }}>
                                <Select
                                  className="assigneeDropDown"
                                  isLoading={isUserLoading}
                                  placeholder={t('page.select_assignee_placeholder')}
                                  options={
                                    employeeOptions && employeeOptions.length > 0
                                      ? employeeOptions.sort((a, b) =>
                                          a.label.localeCompare(b.label, 'en', { numeric: true })
                                        )
                                      : []
                                  }
                                  onChange={(selectedOptions) => {
                                    formik.setFieldValue('assigned_user_id', selectedOptions);
                                  }}
                                  value={defaultValue(
                                    employeeOptions,
                                    formik.values.assigned_user_id?.value
                                  )}
                                  formatOptionLabel={function (data) {
                                    return (
                                      <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                    );
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={1} xs={1}>
                              <TNButton
                                className="saveAssignee"
                                type="submit"
                                style={{ float: 'right' }}>
                                <FontAwesomeIcon isdirtyform={1} icon={faFloppyDisk} />
                              </TNButton>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </>
                )}
                <br />
                <h5
                  className="react_quill_content_data"
                  dangerouslySetInnerHTML={{ __html: taskDetails.description }}></h5>
              </Col>
            </Row>
            {shouldShowRoute('projects.task.comment.index') ? (
              <Row className="subCommentsHistoryTabs">
                <Col lg={12} xs={12}>
                  {shouldShowRoute('projects.task.comment.index') && (
                    <span
                      onClick={() => {
                        setActiveTab(1);
                      }}
                      className={activeTab == 1 ? 'active' : ''}>
                      Comments
                    </span>
                  )}
                  {shouldShowRoute('projects.task.workLog.index') && (
                    <span
                      onClick={() => {
                        setActiveTab(2);
                      }}
                      style={{ marginRight: activeTab == 2 && '3px' }}
                      className={activeTab == 2 ? 'active' : ''}>
                      Work Logs
                    </span>
                  )}
                  {shouldShowRoute('projects.task.workLog.store') && activeTab == 2 && (
                    <button
                      onClick={handleShow}
                      style={{ paddingLeft: '0px' }}
                      className="work-log-button">
                      ({t('page.add_log')})
                    </button>
                  )}
                  {shouldShowRoute('projects.task.history.index') && (
                    <span
                      onClick={() => {
                        setActiveTab(3);
                      }}
                      className={activeTab == 3 ? 'active' : ''}>
                      Work History
                    </span>
                  )}
                  <Row>
                    {shouldShowRoute('projects.task.comment.index') &&
                      activeTab == 1 &&
                      userData && (
                        <>
                          <ListCommentPage
                            t={t}
                            getProfileImageURL={getProfileImageURL}
                            getImageName={getImageName}
                            shouldShowRoute={shouldShowRoute}
                            userData={userData}
                            setPopupImage={setPopupImage}
                            popupImage={popupImage}
                            socket={socket}
                            projectTeamMembers={projectTeamMembers}
                          />
                        </>
                      )}
                    {
                      // work log tab
                      shouldShowRoute('projects.task.workLog.index') && activeTab == 2 && (
                        <ListWorkLogPage
                          t={t}
                          socket={socket}
                          userData={userData}
                          getImageName={getImageName}
                          show={show}
                          setShow={setShow}
                          handleShow={handleShow}
                          handleClose={handleClose}
                          shouldShowRoute={shouldShowRoute}
                          editShow={editShow}
                          setEditShow={setEditShow}
                          handleEditShow={handleEditShow}
                          handleEditClose={handleEditClose}
                          getProfileImageURL={getProfileImageURL}
                          projectTeamMembers={projectTeamMembers}
                        />
                      )
                    }
                    {shouldShowRoute('projects.task.history.index') && activeTab == 3 && (
                      <ListHistoryPage
                        t={t}
                        socket={socket}
                        getImageName={getImageName}
                        getProfileImageURL={getProfileImageURL}
                        projectTeamMembers={projectTeamMembers}
                      />
                    )}
                    {popupImage && (
                      <Lightbox
                        hideDownload={false}
                        medium={popupImage}
                        large={popupImage}
                        alt="Comment Box Image"
                        onClose={() => {
                          setPopupImage(null);
                        }}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
        </Card>
      )}
    </>
  );
};
ViewTaskPage.propTypes = {
  t: PropTypes.func
};
export default ViewTaskPage;
