import React from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap'; // Adjust import based on your table component

const ChildTable = ({ columns, data, t }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    t
  });

  return (
    <Table {...getTableProps()} className="child-table">
      <thead>
        {headerGroups.map((headerGroup, headerGroupKey) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupKey}>
            {headerGroup.headers.map((column, headerColKey) => (
              <th {...column.getHeaderProps()} key={headerColKey}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={rows.length === 0 ? 'd-none' : ''}>
        {rows.map((row, key) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={key}>
              {row.cells.map((cell, cKey) => {
                return (
                  <td {...cell.getCellProps()} key={cKey}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tbody {...getTableBodyProps()} className={rows.length === 0 ? '' : 'd-none'}>
        <tr>
          <td colSpan={columns.length}>{t('page.no_data_found_table')}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ChildTable;
