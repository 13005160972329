import * as Yup from 'yup';
let validationSchema = Yup.object({
    work_log_date: Yup.string(('page.add_work_log_date_required')).trim().max(255, ('page.add_task_name_validation_max')).required(
        ('page.add_work_log_date_required'),
    ),
    time_spent_in_hour: Yup.number()
    .max(10, ('page.work_log_hours_max_limit'))
    .test('either-or', ('page.either_hours_or_minutes_must_be_provided'), function (value) {
        const { time_spent_in_minute } = this.parent;
        return (value != '' && value > 0)  || time_spent_in_minute > 0;
    })
    .nullable(), 
    time_spent_in_minute: Yup.number()
        .max(60, ('page.work_log_minutes_max_limit'))
        .test('either-or', ('page.either_hours_or_minutes_must_be_provided'), function (value) {
            const { time_spent_in_hour } = this.parent;
            return value > 0 || (time_spent_in_hour && time_spent_in_hour != '') || time_spent_in_hour > 0; // Ensure that minutes are provided or hours are non-empty
        })
        .nullable(), 
    description: Yup.string(('page.add_work_description_required')).required(
        ('page.add_work_description_required'),
    )
});
export default validationSchema;