// React Dependencies
import { React } from "react";
// Common Dependencies
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Components
import { Button, Form, Card, Row, Col } from 'react-bootstrap';
import { TNBreadCurm, TNButton } from '@common/components';
import Select from 'react-select';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
// API
import { useUpdateTaskStatusType, useViewTaskStatusType } from '@hooks';
// Validation
import { useFormik } from 'formik';
import validationSchema from './AddEditTaskStatusTypeValidation';
// Helper
import { defaultValue } from '@helpers';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from "../../../../helpers/CommonSEOTitle";
const EditTaskStatusTypePage = ({ t }) => {
    // Navigate
    const navigate = useNavigate();
    // Project ID And Task Status Type ID
    let { id, taskStatusTypeId } = useParams();
    // Status Options
    const options = [
        { value: '1', label: `${t('page.active_status_name')}` },
        { value: '0', label: `${t('page.inactive_status_name')}` }
    ];
    // API Call For Update
    const { mutate: doEditTaskStatusType } = useUpdateTaskStatusType((response) => {
        toast.success(response.message);
        formik.resetForm();
        navigate(`/projects/view/${id}/task-status-type`);
    }, (error) => {
        if (Array.isArray(error.message)) {
            error.message.map((er) => {
                toast.error(er);
            })
        } else {
            toast.error(error.message);
        }
    });
    // Validation
    const formik = useFormik({
        initialValues: {
            project_id: id,
            id: taskStatusTypeId,
            status_name: '',
            sequence: 0,
            is_active: '1'
        },
        validationSchema,
        onSubmit: (values) => {
            doEditTaskStatusType(values);
        },
    });
    // On Load Set Values
    const { isLoading } = useViewTaskStatusType(id, taskStatusTypeId, ({ data: taskStatusTypeData }) => {
        if (taskStatusTypeData) {
            formik.values.status_name = taskStatusTypeData.status_name;
            formik.values.sequence = taskStatusTypeData.sequence;
            if (taskStatusTypeData.is_active == 'Active') {
                formik.values.is_active = '1';
            } else {
                formik.values.is_active = '0';
            }
        }
    },
        (error) => {
            if (error?.data?.redirect == true) {
                toast.error(error.message);
                navigate('/dashboard');
            } else {
                toast.error(error.message);
            }
        });
    // Cancel Form
    const handleCancel = () => {
        if (formik.dirty && formik.dirty !== undefined) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="alert-box">
                            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
                            <div className="alert-popup">
                                <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
                                <Button className="table-delete-button" onClick={() => { onClose(); navigate(`/projects/view/${id}/task-status-type`); }}>
                                    {t('page.alert_popup_yes_button')}
                                </Button>
                                <Button className="table-primary-button" onClick={onClose}>
                                    {t('page.alert_popup_no_button')}
                                </Button>
                            </div>
                        </div>
                    );
                },
            });
        } else {
            navigate(`/projects/view/${id}/task-status-type`);
        }
    };
    return (
        <>
            <CommonSEOTitle
                title={t('page.project_task_status_edit_title')}
            />
            <TNBreadCurm
                breadcurmArray={[
                    {
                        label: t('page.project_list_label'),
                        link: '/projects/list',
                    },
                    {
                        label: t('page.view_project_label'),
                        active: 'active',
                    },
                ]}
            />
            <h1>{t('page.view_project_label')}</h1>
            <ProjectNavigation t={t} id={id} activeLabel='Task Status Type' />
            <Card className="inner-box">
                <h1 className="page-heading-center">{t('page.project_task_status_edit_title')}</h1>
                <div className="edit-profile-form">
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label className="field-label field-label-top">{t('page.project_task_status_type_name_label')}</Form.Label>
                                    <Form.Control
                                        className={
                                            ' ' +
                                            (formik.touched.status_name && formik.errors.status_name
                                                ? 'form-field-error'
                                                : formik.touched.status_name && !formik.errors.status_name
                                                    ? 'form-field-success'
                                                    : '')
                                        }
                                        type="text"
                                        name="status_name"
                                        placeholder={t('page.project_task_status_type_name_placeholder')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.status_name}
                                    />
                                    <div className="form-field-error-text">
                                        {formik.touched.status_name && formik.errors.status_name ? (
                                            <div>{t(formik.errors.status_name)}</div>
                                        ) : null}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label className="field-label field-label-top">{t('page.project_task_status_type_sequence_label')}</Form.Label>
                                    <Form.Control
                                        className={
                                            ' ' +
                                            (formik.touched.sequence && formik.errors.sequence
                                                ? 'form-field-error'
                                                : formik.touched.sequence && !formik.errors.sequence
                                                    ? 'form-field-success'
                                                    : '')
                                        }
                                        type="text"
                                        name="sequence"
                                        placeholder={t('page.project_task_status_type_sequence_placeholder')}
                                        onChange={(event) => {
                                            const re = /^[0-9\b]+$/;
                                            if (event.target.value === '' || re.test(event.target.value)) {
                                                // formik.handleChange
                                                formik.setFieldValue('sequence', event.target.value);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.sequence}
                                    />
                                    <div className="form-field-error-text">
                                        {formik.touched.sequence && formik.errors.sequence ? (
                                            <div>{t(formik.errors.sequence)}</div>
                                        ) : null}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className="field-label field-label-top">
                                        {t('page.project_status_label')}
                                    </Form.Label>
                                    <Select
                                        placeholder={t('page.select_status')}
                                        options={[
                                            { value: '1', label: t('page.active_status_name') },
                                            { value: '0', label: t('page.inactive_status_name') }
                                        ]}
                                        className={
                                            ' ' +
                                            (formik.touched.is_active && formik.errors.is_active
                                                ? 'form-select-error'
                                                : formik.touched.is_active && !formik.errors.is_active
                                                    ? 'form-select-success'
                                                    : '')
                                        }
                                        value={defaultValue(options, formik.values.is_active)}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('is_active', selectedOption.value);
                                        }}
                                    />
                                    <div className="form-field-error-text">
                                        {formik.touched.is_active && formik.errors.is_active ? (
                                            <div>{t(formik.errors.is_active)}</div>
                                        ) : null}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="primary-button">
                            <span className="link-center" onClick={handleCancel}>
                                {t('page.cancel_button_text')}
                            </span>
                            <TNButton
                                type="submit"
                                loading={isLoading}
                                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                                {t('page.save_button_text')}
                            </TNButton>
                        </div>
                    </Form>
                </div>
            </Card>
        </>
    );
};
EditTaskStatusTypePage.propTypes = {
    t: PropTypes.func,
};
export default EditTaskStatusTypePage;