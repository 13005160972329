// React Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import CommonSEOTitle from '../../../helpers/CommonSEOTitle';
const DashboardPage = ({ t }) => {
  return (
    <>
      <CommonSEOTitle title={t('page.dashboard_page_label')} />
      <h1> {t('page.dashboard_page_label')}</h1>
    </>
  );
};
DashboardPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { DashboardPage };
