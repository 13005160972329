// React Dependencies
import { React, useRef, useState } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Component
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { addSetting } from '../../store/features/settingSlice';
import { TNButton } from '@common/components/TNButton';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
// Validation
import { useFormik } from 'formik';
import validationSchema from './GeneralSettingValidation';
// API
import { useStoreSettingData, useGetSettingDataAlways, useRouteAccessList, useGetAllSettingData } from '@hooks';
// Asset
import '../../../assets/scss/page/_general_setting.scss';
import CommonSEOTitle from '../../../helpers/CommonSEOTitle';
const GeneralSetting = ({ t }) => {
  // Navigate And Dispatch
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Reference
  let dashboardRef = useRef();
  let faviconRef = useRef();
  let loginRef = useRef();
  let emailRef = useRef();
  // States
  const [getSettingData, setSettingData] = useState();
  const [routeName, setRouteNames] = useState([]);
  // Route Access List
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  // Setting Data Initialization
  useGetAllSettingData(({ data: setting }) => {
    const dataStore = {
      dashboard_logo: setting?.common_header_logo,
      front_logo: setting?.common_login_logo,
      email_logo: setting?.common_email_header_logo,
      favicon_icon: setting?.common_favicon_icon,
      seo_title: setting?.seo_title,
      seo_description: setting?.seo_description,
      setting_get: false
    };
    setSettingData(dataStore);
  }, (error) => {
     if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
  });
  // Image Preview
  const imagePreviewFormik = (file) => {
    return typeof file == 'string' ? file : URL.createObjectURL(file);
  };
  // Fetch Settings Data
  const { mutate: doStoreSettingData } = useStoreSettingData(
    (response) => {
      toast.success(response.message);
      const dataStore = {
        dashboard_logo: response?.data?.common_header_logo,
        front_logo: response?.data?.common_login_logo,
        email_logo: response?.data?.common_email_header_logo,
        favicon_icon: response?.data?.common_favicon_icon,
        seo_title: response?.data?.seo_title,
        seo_description: response?.data?.seo_description,
        common_dirty_email_data: response?.data?.common_dirty_email_data,
        setting_get: true
      };
      dispatch(addSetting(dataStore));
      setTimeout(function () {
        navigate('/dashboard');
      }, 1500);
    }
  );
  // Cancel Form
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h5
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h5>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/dashboard/`, { replace: true });
    }
  };
  // Set Settings Data
  useGetSettingDataAlways(
    ({ data: setting }) => {
      if (setting) {
        formik.values.email_logo = setting.common_email_header_logo;
        formik.values.home_logo = setting.common_header_logo;
        formik.values.login_logo = setting.common_login_logo;
        formik.values.favicon_icon = setting.common_favicon_icon;
        formik.values.seo_title = setting.seo_title;
        formik.values.seo_description = setting.seo_description;
        formik.values.common_dirty_email_data = setting.common_dirty_email_data;
      }
    }, (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
    }
  );
  // Formik Initialization
  const formik = useFormik({
    initialValues: {
      email_logo: getSettingData?.common_email_header_logo,
      home_logo: getSettingData?.common_header_logo,
      login_logo: getSettingData?.common_login_logo,
      favicon_icon: getSettingData?.common_favicon_icon,
      seo_title: getSettingData?.seo_title,
      seo_description: getSettingData?.seo_description,
      common_dirty_email_data: getSettingData?.common_dirty_email_data ?? ''
    },
    validationSchema,
    onSubmit: (values) => {
      doStoreSettingData(values);
    }
  });
  return (
    <>
      <CommonSEOTitle
        title={t('page.general_setting_tab_general_setting')}
      />
      <Nav className="tab-navigation">
        {shouldShowRoute('setting.index') &&
          <Nav.Item>
            <NavLink to="/setting/general">{t('page.general_setting_tab_general_setting')}</NavLink>
          </Nav.Item>
        }
      </Nav>
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.general_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_dashboard_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={dashboardRef}
                      name="home_logo"
                      onChange={(event) => {
                        formik.setFieldValue('home_logo', event.currentTarget.files[0]);
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => dashboardRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.home_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_logo && formik.errors.home_logo ? (
                      <div>{t(formik.errors.home_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_favicon_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={faviconRef}
                      name="favicon_icon"
                      onChange={(event) => {
                        formik.setFieldValue('favicon_icon', event.currentTarget.files[0]);
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => faviconRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.favicon_icon && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.favicon_icon)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('favicon_icon', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.favicon_icon && formik.errors.favicon_icon ? (
                      <div>{t(formik.errors.favicon_icon)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_login_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={loginRef}
                      name="login_logo"
                      onChange={(event) => {
                        formik.setFieldValue('login_logo', event.currentTarget.files[0]);
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => loginRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.login_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.login_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('login_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.login_logo && formik.errors.login_logo ? (
                      <div>{t(formik.errors.login_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_email_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={emailRef}
                      name="email_logo"
                      onChange={(event) => {
                        formik.setFieldValue('email_logo', event.currentTarget.files[0]);
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => emailRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.email_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.email_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('email_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.email_logo && formik.errors.email_logo ? (
                      <div>{t(formik.errors.email_logo)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_seo_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.seo_title && formik.errors.seo_title
                          ? 'form-field-error'
                          : formik.touched.seo_title && !formik.errors.seo_title
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="seo_title"
                      placeholder={t('page.general_setting_seo_title_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.seo_title ?? ''}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.seo_title && formik.errors.seo_title ? (
                        <div>{t(formik.errors.seo_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_seo_description_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.seo_description && formik.errors.seo_description
                          ? 'form-field-error'
                          : formik.touched.seo_description && !formik.errors.seo_description
                            ? 'form-field-success'
                            : '')
                      }
                      as="textarea"
                      rows={3}
                      type="text"
                      name="seo_description"
                      placeholder={t('page.general_setting_seo_description_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.seo_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.seo_description && formik.errors.seo_description ? (
                        <div>{t(formik.errors.seo_description)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_common_dirty_email_data_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.common_dirty_email_data &&
                          formik.errors.common_dirty_email_data
                          ? 'form-field-error'
                          : formik.touched.common_dirty_email_data &&
                            !formik.errors.common_dirty_email_data
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="common_dirty_email_data"
                      placeholder={t('page.general_setting_common_dirty_email_data_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.common_dirty_email_data}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.common_dirty_email_data &&
                        formik.errors.common_dirty_email_data ? (
                        <div>{t(formik.errors.common_dirty_email_data)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                    {' '}
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
GeneralSetting.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { GeneralSetting };