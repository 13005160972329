import client from '@libs/HttpClient';
class TaskWorkLogService {
  static listTaskWorkLog(request) {
    const projectId = request.project_id;
    const projectTaskId = request.project_task_id;
    const workLogPage = request.workLogPage;
    return client.get(`/projects/view/${projectId}/task/${projectTaskId}/work-logs?page=${workLogPage}`,request);
  }
  static addTaskWorkLog(request) {
    const projectId = request.project_id;
    const projectTaskId = request.project_task_id;
    return client.post(`/projects/view/${projectId}/task/${projectTaskId}/work-log`, request);
  }
  static updateTaskWorkLog(request) {
    const projectId = request.project_id;
    const projectTaskId = request.project_task_id;
    const projectTaskWorkLogId = request.project_task_work_log_id;
    return client.put(`/projects/view/${projectId}/task/${projectTaskId}/work-log/${projectTaskWorkLogId}`, request);
  }
  static deleteTaskWorkLog(projectId, projectTaskId, projectTaskWorkLogId) {
    return client.delete(`/projects/view/${projectId}/task/${projectTaskId}/work-log/${projectTaskWorkLogId}`);
  }
  static editTaskWorkLog(projectId, projectTaskId, projectTaskWorkLogId) {
    return client.get(`/projects/view/${projectId}/task/${projectTaskId}/work-log/${projectTaskWorkLogId}/edit`);
  }
}
export { TaskWorkLogService };