// React Dependencies
import { useState, React, useEffect } from 'react';
// Common Dependencies
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loggedUser } from '../../../../store/features/authSlice';
// Component
import { Form, Card, Row, Col, Badge, Button } from 'react-bootstrap';
import { TNButton } from '@common/components';
// confirmAlert alert
import { confirmAlert } from 'react-confirm-alert';
// Add Work Log Popup
import AddWorkLogPopup from './AddWorkLogPopup';
// Edit Work Log Popup
import EditWorkLogPopup from './EditWorkLogPopup';
// hooks
import { useListTaskWorkLog, useDeleteTaskWorkLog, useEditTaskWorkLog } from '@hooks';
// Asset
import '@assets/scss/page/_taskView.scss';
// Moment
import moment from 'moment';
// helper
import { convertTimeInHoursAndMinutes } from '../../../../../helpers/commonHelpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// icons
import { faEdit, faFloppyDisk, faTrash, faXmark, faClose } from '@fortawesome/free-solid-svg-icons';
// Alert
import { toast } from 'react-toastify';

const ListWorkLogPage = ({
  t,
  userData,
  shouldShowRoute,
  socket,
  getImageName,
  handleShow,
  handleClose,
  show,
  setShow,
  handleEditShow,
  handleEditClose,
  editShow,
  setEditShow,
  getProfileImageURL,
  projectTeamMembers
}) => {
  const [workLogs, setWorkLogs] = useState([]);
  const [editWorkLog, setEditWorkLog] = useState({});
  // const [workLogLoading, setWorkLogLoading] = useState(true);
  // Page number for work log
  const [workLogPage, setWorkLogPage] = useState(1);
  const [hasMoreWorkLogs, setHasMoreWorkLogs] = useState(true);

  // Project ID And Task ID
  let { id, projectTaskId } = useParams();

  // Socket Initialization
  useEffect(() => {
    socket.on('get-latest-worklog', () => {
      setWorkLogPage(1);
      refetch();
    });
  }, []);
  // Fetch Records
  const {
    refetch,
    isLoading: workLogLoading,
    isFetching
  } = useListTaskWorkLog(
    [id, projectTaskId, workLogPage],
    (res) => {
      // setWorkLogLoading(false)
      if (res?.data?.pagination.current_page == res?.data?.pagination.last_page) {
        setHasMoreWorkLogs(false);
      } else {
        setHasMoreWorkLogs(true);
      }
      if (res?.data?.pagination.current_page == 1) {
        setWorkLogs(res?.data?.project_task_work_log_list);
      } else {
        setWorkLogs((prevData) => [...prevData, ...res?.data?.project_task_work_log_list]);
      }
    },
    (error) => {
      console.log(error, 'Error');
    }
  );

  useEffect(() => {
    refetch(workLogPage);
  }, [workLogPage]);

  const loadMore = () => {
    if (hasMoreWorkLogs && !workLogLoading && !isFetching) {
      setWorkLogPage((prevPage) => prevPage + 1);
    }
  };

  // Delete log
  const { deleteTaskWorkLog } = useDeleteTaskWorkLog();
  const handleWorkLogDeleteClick = (id, projectTaskId, projectTaskWorkLogId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_task_work_log_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTaskWorkLog(
                      id,
                      projectTaskId,
                      projectTaskWorkLogId
                    );
                    if (response && response.message) {
                      toast.success(response.message);
                    }
                    onClose();
                    socket.emit('worklog-updated');
                    if (workLogPage == 1) {
                      refetch();
                    } else {
                      setWorkLogPage(1);
                    }
                  } catch (error) {
                    onClose();
                    toast.error(error.message);
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };

  // edit log
  const { editTaskWorkLog } = useEditTaskWorkLog();
  const handleWorkLogEditClick = async (id, projectTaskId, projectTaskWorkLogId) => {
    try {
      const response = await editTaskWorkLog(id, projectTaskId, projectTaskWorkLogId);
      if (response && response.data) {
        setEditWorkLog(response.data);
      }
      handleEditShow();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Col className="tabDiv" lg={12} xs={12}>
        {
          // workLogs && workLogs.length > 0 ? (
          workLogs.map((log, ind) => (
            <Row key={ind} style={{ marginBottom: '5px' }}>
              <Col lg={12} xs={12}>
                <Row>
                  <Col lg={1} xs={1} sm={1} style={{ width: '4%' }}>
                    {log?.user?.profile_image && log?.user?.profile_image != '' ? (
                      <div
                        data-toggle="tooltip"
                        data-placement="top"
                        title={log?.user?.full_name}
                        className="profileImageWithImage">
                        <img
                          src={getProfileImageURL(log?.user?.user_id, log?.user?.profile_image)}
                        />
                      </div>
                    ) : (
                      <div
                        data-toggle="tooltip"
                        data-placement="top"
                        title={log?.user?.full_name}
                        className="profileImageWithoutImage">
                        {getImageName(log?.user?.first_name, log?.user?.last_name)}
                      </div>
                    )}
                  </Col>
                  <Col lg={11} xs={11} sm={11} style={{ paddingTop: '4px' }}>
                    {log.user.full_name} &nbsp;logged{' '}
                    <b>
                      {' '}
                      {convertTimeInHoursAndMinutes(
                        log.time_spent_in_hour,
                        log.time_spent_in_minute
                      )}{' '}
                    </b>{' '}
                    For {log.work_log_date_for_show} At {log.formatted_created_at_for_show}{' '}
                    {log?.updated_at != null && log?.updated_at != '' ? (
                      <b>
                        <sup>&nbsp;{t('page.edited')}</sup>
                      </b>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={2} sm={2} style={{ width: '4%' }}></Col>
                  <Col lg={11} xs={10} sm={10} className="react_quill_content_data">
                    <div dangerouslySetInnerHTML={{ __html: log.description }} />
                    {/* <pre style={{ fontSize: "unset", marginBottom: "0px", fontFamily: "unset" }}>{log.description.trim()}</pre> */}
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={2} sm={2} style={{ width: '4%' }}></Col>
                  <Col lg={11} xs={10} sm={10} style={{ paddingTop: '5px' }}>
                    {shouldShowRoute('projects.task.workLog.update') &&
                    (userData?.user_id == log?.user?.user_id || userData?.user_type == 1) ? (
                      <button
                        style={{ paddingLeft: '0px' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleWorkLogEditClick(id, projectTaskId, log?.project_task_work_log_id);
                        }}
                        className="work-log-button">
                        {t('page.edit')}
                      </button>
                    ) : (
                      ''
                    )}
                    &nbsp;
                    {shouldShowRoute('projects.task.workLog.destroy') &&
                    (userData?.user_id == log?.user?.user_id || userData?.user_type == 1) ? (
                      <button
                        style={{ paddingLeft: '0px' }}
                        className="work-log-button"
                        onClick={() => {
                          handleWorkLogDeleteClick(
                            id,
                            projectTaskId,
                            log?.project_task_work_log_id
                          );
                        }}>
                        {t('page.delete')}
                      </button>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          ))
        }
        {(workLogLoading || isFetching) && <p>{t('page.loading')}</p>}
        {hasMoreWorkLogs && !(workLogLoading || isFetching) && (
          <button
            className="work-log-button-load_more"
            onClick={loadMore}
            disabled={workLogLoading || isFetching}>
            {t('page.load_more')}
          </button>
        )}
        {workLogs.length <= 0 && !workLogLoading && !isFetching && (
          <p>{t('page.work_log_data_not_found')}</p>
        )}
      </Col>

      {show && (
        <AddWorkLogPopup
          t={t}
          refetch={refetch}
          show={show}
          setShow={setShow}
          socket={socket}
          handleShow={handleShow}
          setWorkLogPage={setWorkLogPage}
          workLogPage={workLogPage}
          handleClose={handleClose}
          projectTeamMembers={projectTeamMembers}
        />
      )}

      {editShow && (
        <EditWorkLogPopup
          t={t}
          refetch={refetch}
          editShow={editShow}
          setEditShow={setEditShow}
          handleEditShow={handleEditShow}
          socket={socket}
          handleEditClose={handleEditClose}
          setWorkLogPage={setWorkLogPage}
          workLogPage={workLogPage}
          editWorkLog={editWorkLog}
          projectTeamMembers={projectTeamMembers}
        />
      )}
    </>
  );
};
ListWorkLogPage.propTypes = {
  t: PropTypes.func
};
export default ListWorkLogPage;
