import { useMutation, useQuery } from 'react-query';
import { UserDepartmentAndDesignationService } from '@api/UserDepartmentAndDesignationService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListUserDepartmentAndDesignation = (
  [id, page_no, searchText, status, startDate, endDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['user-department-and-designation-list', id, page_no, searchText, status, startDate, endDate],
    () =>
      UserDepartmentAndDesignationService.listUserDepartmentAndDesignationService({
        id,
        page_no,
        searchText,
        status,
        startDate,
        endDate
      }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Remove
const useDeleteUserDepartmentAndDesignation = () => {
  const deleteUserDepartmentAndDesignation = async (userId, id) => {
    return await UserDepartmentAndDesignationService.deleteUserDepartmentAndDesignationService(
      userId,
      id
    );
  };
  return { deleteUserDepartmentAndDesignation };
};
// Create
const useAddUserDepartmentAndDesignation = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserDepartmentAndDesignationService.addUserDepartmentAndDesignationService, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateUserDepartmentAndDesignation = (onSuccess, onError = onDefaultError) => {
  return useMutation(
    UserDepartmentAndDesignationService.updateUserDepartmentAndDesignationService,
    {
      onSuccess,
      onError
    }
  );
};
// Get Details
const useEditUserDepartmentAndDesignation = (userId, id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'user-department-and-designation-edit',
    () => UserDepartmentAndDesignationService.viewUserDepartmentAndDesignationService(userId, id),
    {
      onSuccess,
      onError
    }
  );
};

export {
  useDeleteUserDepartmentAndDesignation,
  useListUserDepartmentAndDesignation,
  useAddUserDepartmentAndDesignation,
  useUpdateUserDepartmentAndDesignation,
  useEditUserDepartmentAndDesignation
};
