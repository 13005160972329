import { useMutation, useQuery } from 'react-query';
import { TaskService } from '@api/TaskService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListTask = (
  [id, page_no, searchText, selectedPriority, selectedAssignee, selectedSprint],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['task-list', id, page_no, searchText, selectedPriority, selectedAssignee, selectedSprint],
    () =>
      TaskService.listTask({
        id,
        page_no,
        searchText,
        selectedPriority,
        selectedAssignee,
        selectedSprint
      }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Remove
const useDeleteTask = () => {
  const deleteTask = async (projectId, id) => {
    return await TaskService.deleteTask(projectId, id);
  };
  return { deleteTask };
};
// Add
const useAddTask = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskService.addTask, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateTask = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskService.updateTask, {
    onSuccess,
    onError
  });
};
// Get Details
const useEditTask = (projectId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('task-edit', () => TaskService.viewTask(projectId, id), {
    onSuccess,
    onError
  });
};
// Get Task Status Types
const useGetTaskStatusTypes = (projectId, onSuccess, onError = onDefaultError) => {
  return useQuery('task-status-type', () => TaskService.getTaskStatusTypes(projectId), {
    onSuccess,
    onError
  });
};
// Get Sprint List
const useGetSprintList = (projectId, onSuccess, onError = onDefaultError) => {
  return useQuery('sprint-list', () => TaskService.getSprintList(projectId), {
    onSuccess,
    onError
  });
};
// Get Task Priorities
const useGetTaskPriorities = (onSuccess, onError = onDefaultError) => {
  return useQuery('task-priority-list', TaskService.getTaskPriorities, {
    onSuccess,
    onError
  });
};
// Get Task Details
const useGetTaskDetails = (projectId, id, onSuccess, onError = onDefaultError) => {
  return useQuery('task-details', () => TaskService.getTaskDetails(projectId, id), {
    onSuccess,
    onError
  });
};
// Get Employee List
const useGetAssignedProjectEmployeeList = (
  projectId,
  status = 0,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'task-employee-list',
    () => TaskService.getAssignedProjectEmployeeList(projectId, status),
    {
      onSuccess,
      onError
    }
  );
};
// Update Assignees
const useUpdateAssignees = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskService.updateTaskAssignees, {
    onSuccess,
    onError
  });
};
// Get Task Status Types With Sub Tasks
const useGetTaskStatusTypesWithSubTasks = (projectId, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'task-status-type-with-sub-tasks',
    () => TaskService.getTaskStatusTypesWithSubTasks(projectId),
    {
      onSuccess,
      onError
    }
  );
};
// Update Task Status
const useUpdateTaskStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskService.updateTaskStatus, {
    onSuccess,
    onError
  });
};
export {
  useDeleteTask,
  useListTask,
  useAddTask,
  useUpdateTask,
  useEditTask,
  useGetTaskStatusTypes,
  useGetSprintList,
  useGetTaskPriorities,
  useGetTaskDetails,
  useGetAssignedProjectEmployeeList,
  useUpdateAssignees,
  useGetTaskStatusTypesWithSubTasks,
  useUpdateTaskStatus
};
