import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
const EditUserDepartmentAndDesignation = React.lazy(
  () => import('./EditUserDepartmentAndDesignation')
);
const AddUserDepartmentAndDesignation = React.lazy(
  () => import('./AddUserDepartmentAndDesignation')
);
const ViewUserDepartmentAndDesignation = React.lazy(
  () => import('./ViewUserDepartmentAndDesignation')
);
const UserDepartmentAndDesignationRoutes = ({ t }) => {
  return [
    <Route
      key="view_user_department_and_designation"
      exact
      path="/view/:id/designation-department"
      element={<ViewUserDepartmentAndDesignation t={t} />}
    />,
    <Route
      key="add_user_department_and_designation"
      exact
      path="/view/:id/designation-department/add"
      element={<AddUserDepartmentAndDesignation t={t} />}
    />,
    <Route
      key="edit_user_department_and_designation"
      exact
      path="/view/:id/designation-department/:departmentId/edit"
      element={<EditUserDepartmentAndDesignation t={t} />}
    />
  ];
};
UserDepartmentAndDesignationRoutes.propTypes = {
  t: PropTypes.func
};
export default UserDepartmentAndDesignationRoutes;
