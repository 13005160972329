import { useMutation, useQuery } from 'react-query';
import { HolidaysEtcService } from '@api/HolidaysEtcService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListHolidaysEtc = ([page_no, searchText,selectedType,selectedDateRange], onSuccess, onError = onDefaultError) => {
  return useQuery(['holidays-etc-list', page_no, searchText,selectedType,selectedDateRange], HolidaysEtcService.listUserHolidaysEtc, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddHolidaysEtc = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidaysEtcService.addUserHolidaysEtc, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateHolidaysEtc = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidaysEtcService.updateUserHolidaysEtc, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteHolidaysEtc = (onSuccess, onError = onDefaultError) => {
  return useMutation(HolidaysEtcService.deleteHolidaysEtc, {
    onSuccess,
    onError
  });
};
// Details
const useViewHolidaysEtc = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('department-view', () => HolidaysEtcService.viewHolidaysEtc(id), {
    onSuccess,
    onError
  });
};
//  Type List
const useGetHolidayEtcTypeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('holidays-etc/type-list', HolidaysEtcService.useGetHolidayEtcTypeList, {
    onSuccess,
    onError
  });
};
export {
  useListHolidaysEtc,
  useAddHolidaysEtc,
  useViewHolidaysEtc,
  useUpdateHolidaysEtc,
  useDeleteHolidaysEtc,
  useGetHolidayEtcTypeList
};