import React from 'react';
import { Route } from 'react-router-dom';
import ListTaskPage from './ListTaskPage';
import AddTaskPage from './AddTaskPage';
import EditTaskPage from './EditTaskPage';
import ViewTaskPage from './ViewTaskPage';
// Socket
import { io } from 'socket.io-client';
console.log('process?.env?.MIX_COMMON_SOCKET_URL:', process.env.MIX_COMMON_SOCKET_URL);
const socketURL = process.env.MIX_COMMON_SOCKET_URL ?? 'https://technource.co.in:3003';
const socket = io(socketURL, {
  transports: ['websocket', 'polling'], // Optional: ensure WebSocket is used
  secure: true // Optional: ensure SSL/TLS
});

const TaskRoutes = ({ t }) => {
  return [
    <Route
      key={'tasK_view'}
      exact
      path="/view/:id/task"
      element={<ListTaskPage t={t} socket={socket} />}
    />,
    <Route
      key={'tasK_add'}
      exact
      path="/view/:id/task/add"
      element={<AddTaskPage t={t} socket={socket} />}
    />,
    <Route
      key={'tasK_edit'}
      exact
      path="/view/:id/task/:projectTaskId/edit"
      element={<EditTaskPage t={t} socket={socket} />}
    />,
    <Route
      key={'tasK_details'}
      exact
      path="/view/:id/task/:projectTaskId/details"
      element={<ViewTaskPage t={t} socket={socket} />}
    />
  ];
};
TaskRoutes.propTypes = {};
export default TaskRoutes;
