// React Dependencies
import { useState, React, useEffect } from 'react';
// Common Dependencies
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loggedUser } from '../../../store/features/authSlice';
// Component
import { Form, Card, Row, Col, Badge, Button } from 'react-bootstrap';
import { TNButton } from '@common/components';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';

// import makeAnimated from 'react-select/animated';
import Select from 'react-select';
// Validation
import { useFormik } from 'formik';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFloppyDisk, faTrash, faXmark, faClose } from '@fortawesome/free-solid-svg-icons';

// API
import {
  useRouteAccessList,
  useGetTaskDetails,
  useGetSprintList,
  useGetTaskPriorities,
  useGetTaskStatusTypes,
  useGetAssignedProjectEmployeeList,
  useUpdateAssignees,
  useEditTask,
  useDeleteTask
} from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Asset
import '@assets/scss/page/_taskView.scss';
// Image Popup
import { Lightbox } from 'react-modal-image';

import ListWorkLogPage from './WorkLog/ListWorkLogPage';
import ListHistoryPage from './History/ListHistoryPage';
import ListCommentPage from './Comments/ListCommentPage';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';

const ViewTaskPage = ({ t, socket }) => {
  // Auth Data
  const checkLoggedInUser = useSelector(loggedUser);
  // Navigate
  const navigate = useNavigate();
  // Project ID And Task ID
  let { id, projectTaskId } = useParams();
  // Get the value from parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const viewType = queryParams.get('viewType') ?? 2;
  // Animated Component Set
  // const animatedComponents = makeAnimated();
  // States
  const [taskDetails, setTaskDetails] = useState(null);
  const [routeName, setRouteNames] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState('');
  const [showAssigneeDropDown, setShowAssigneeDropDown] = useState(false);
  // Comment, Work Log And History Tab
  const [userData, setUserData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [showEditor, setShowEditor] = useState(false);
  const [commentDescription, setCommentDescription] = useState('');
  const [commentsData, setCommentsData] = useState([]);
  const [projectTaskCommentIdValue, setProjectTaskCommentIdValue] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  // State For Work Log Add Edit Popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);
  // Dropdown Lists
  // Sprint List
  const { data: optionList, isLoading: isSprintLoading } = useGetSprintList(id);
  let options = [];
  if (optionList !== undefined && !isSprintLoading) {
    optionList.data.map((val) => {
      options.push({
        value: val.project_sprint_id,
        label: val.name
      });
      return true;
    });
  }
  // Task Priorities List
  const { data: taskPriorityList, isLoading: isTaskPriorityLoading } = useGetTaskPriorities();
  let options2 = [];
  if (taskPriorityList !== undefined && !isTaskPriorityLoading) {
    Object.entries(taskPriorityList).map(([value, label]) => {
      options2.push({
        value: parseInt(value),
        label: label
      });
      return true;
    });
  }
  // Task Status Type List
  const { data: taskStatusTypeList, isLoading: isTaskStatusTypeLoading } =
    useGetTaskStatusTypes(id);
  const taskStatusTypeOptions = [];
  if (taskStatusTypeList !== undefined && !isTaskStatusTypeLoading) {
    taskStatusTypeList.data.map((val) => {
      taskStatusTypeOptions.push({
        value: val.task_status_type_id,
        label: val.status_name
      });
      return true;
    });
  }
  // Get Employee List
  const { data: employeeList, isLoading: isUserLoading } = useGetAssignedProjectEmployeeList(id);
  const options1 = [];
  const projectTeamMembers = [];

  if (employeeList !== undefined && !isUserLoading) {
    employeeList.data.employee_list.map((val) => {
      options1.push({
        value: val.user_id,
        label: val.first_name + ' ' + val.last_name
      });
      projectTeamMembers.push({
        id: val.user_id,
        value: val.first_name + ' ' + val.last_name,
        first_name: val.first_name,
        last_name: val.last_name,
        profile_image: val.profile_image
      });
      return true;
    });
  }
  // Task Details Fetch
  useGetTaskDetails(
    id,
    projectTaskId,
    ({ data: taskData }) => {
      if (taskData) {
        setTaskDetails(taskData);
      }
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  // Edit And List Handlers
  const handleEditClick = (id, taskId) => {
    navigate(`/projects/view/${id}/task/${taskId}/edit?viewType=${viewType}`);
  };
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };

  // Remove Task Handler
  const { deleteTask } = useDeleteTask();
  const handleDeleteClick = (id, taskStatusTypeId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_task_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTask(id, taskStatusTypeId);
                    if (response && response.message) {
                      toast.success(response.message);
                      socket.emit('task-updated', response?.data);
                    }
                    onClose();
                    navigate(`/projects/view/${id}/task?viewType=${viewType}`);
                  } catch (error) {
                    toast.error(error.message);
                    onClose();
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };

  // Update Task API
  const { mutate: updateTaskAssignees } = useUpdateAssignees((response) => {
    toast.success(response.message);

    let oldTaskDetails = taskDetails.assignee;
    oldTaskDetails.full_name = formik.values?.assigned_user_id?.label;
    setTaskDetails({
      ...taskDetails,
      assignee: oldTaskDetails
    });
    setShowAssigneeDropDown(!showAssigneeDropDown);
  });
  // Task Data Initialize On Load
  useEditTask(
    id,
    projectTaskId,
    ({ data: taskData }) => {
      if (taskData) {
        if (taskData.assignee) {
          // taskData.assignee.map((val) => {
          formik.values.assigned_user_id = {
            value: taskData.assignee.user_id,
            label: taskData.assignee.full_name
          };
          // .push({
          //   value: val.user_id,
          //   label: val.first_name + ' ' + val.last_name
          // });
          return formik.values.assigned_user_id;
          // });
        }
      }
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  // Form Validation And Submit
  const formik = useFormik({
    initialValues: {
      project_id: id,
      id: projectTaskId,
      assigned_user_id: {
        value: taskDetails?.assignee.user_id,
        label: taskDetails?.assignee?.full_name
      }
    },
    onSubmit: (values) => {
      updateTaskAssignees(values);
    }
  });
  // Comments, Work Log And History
  // Profile Image
  // Board View Profile Image
  const getProfileImageURL = (userId, profileImage) => {
    if (profileImage) {
      let imageURL = `${process.env.MIX_AWS_URL}user_profile/${userId}/${profileImage}`;
      return imageURL;
    } else {
      return false;
    }
  };
  const getImageName = (firstName, lastName) => {
    let imageName = 'US';
    if (firstName) {
      imageName = firstName.substring(0, 1);
    }
    if (lastName) {
      imageName += lastName.substring(0, 1);
    }
    return imageName;
  };

  // Set Logged In Custom User Data
  useEffect(() => {
    if (checkLoggedInUser && checkLoggedInUser?.user) {
      let userInfo = checkLoggedInUser?.user;
      userInfo = JSON.parse(JSON.stringify(userInfo));
      userInfo['full_name'] = userInfo?.first_name + ' ' + userInfo?.last_name;
      userInfo['image_name'] = getImageName(userInfo?.first_name, userInfo?.last_name);
      // userInfo['profile_image'] = "";
      setUserData(userInfo);
    }
  }, [checkLoggedInUser]);

  return (
    <>
      <CommonSEOTitle title={t('page.project_task_view_title')} />
      <ProjectNavigation t={t} id={id} activeLabel="Tasks" />
      {taskDetails && (
        <Card className="inner-box">
          <div>
            <Row>
              <Col lg={12} xs={12} className="taskViewDetailsDiv">
                <Row>
                  <Col lg={11} xs={11}>
                    <h4>{taskDetails.name}</h4>
                  </Col>
                  <Col lg={1} xs={1}>
                    {shouldShowRoute('projects.task.destroy') && (
                      <FontAwesomeIcon
                        style={{
                          float: 'right',
                          marginLeft: '10px',
                          fontSize: '20px',
                          color: '#dc3545'
                        }}
                        className="alert-close "
                        icon={faTrash}
                        onClick={() => {
                          handleDeleteClick(id, projectTaskId);
                        }}
                      />
                    )}
                    {shouldShowRoute('projects.task.edit') && (
                      <FontAwesomeIcon
                        style={{ float: 'right', fontSize: '20px' }}
                        className="alert-close"
                        icon={faEdit}
                        onClick={() => {
                          handleEditClick(id, projectTaskId);
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <h5>
                      <Badge key={'sprint'} className="sprintInfo">
                        {options
                          ? options
                              .filter(function (obj) {
                                return obj.value === taskDetails?.project_sprint_id;
                              })
                              .map(function (obj) {
                                return obj.label;
                              })[0]
                          : ''}
                      </Badge>
                    </h5>
                  </Col>
                  <Col lg={8} xs={12}>
                    <Row>
                      <Col lg={11} xs={11}>
                        <div style={{ float: 'right' }}>
                          <h5>
                            <Badge
                              key={'priority'}
                              className={
                                taskDetails?.priority === 3
                                  ? 'highPriority'
                                  : taskDetails?.priority === 2
                                    ? 'mediumPriortity'
                                    : 'lowPriortity'
                              }>
                              {options2
                                ? options2
                                    .filter(function (obj) {
                                      return obj.value === taskDetails?.priority;
                                    })
                                    .map(function (obj) {
                                      return obj.label;
                                    })[0]
                                : ''}
                            </Badge>
                            {' | '}
                            <Badge key={'task_status_info'} className="taskStatusInfo">
                              {taskStatusTypeOptions
                                ? taskStatusTypeOptions
                                    .filter(function (obj) {
                                      return obj.value === taskDetails?.task_status_type_id;
                                    })
                                    .map(function (obj) {
                                      return obj.label;
                                    })[0]
                                : ''}
                            </Badge>
                            {taskDetails?.assignee ? (
                              // taskDetails?.assignee?.map((row, index) => {
                              // return (
                              <div style={{ float: 'right' }} className="assignees_badges_div">
                                {' | '}
                                <Badge className="assigneesBadge">
                                  {taskDetails?.assignee.full_name}
                                </Badge>
                              </div>
                            ) : (
                              // )
                              // })
                              ''
                            )}
                          </h5>
                        </div>
                      </Col>
                      <Col lg={1} xs={1}>
                        {shouldShowRoute('projects.task.update-assignee') && (
                          <FontAwesomeIcon
                            style={{ float: 'right', cursor: 'pointer' }}
                            icon={!showAssigneeDropDown ? faEdit : faXmark}
                            onClick={() => {
                              setShowAssigneeDropDown(!showAssigneeDropDown);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {showAssigneeDropDown && shouldShowRoute('projects.task.update-assignee') && (
                  <>
                    <Row>
                      <Col lg={12} xs={12}>
                        <Form onSubmit={formik.handleSubmit}>
                          <Row>
                            <Col lg={11} xs={11}>
                              <div style={{ float: 'right', marginRight: '-38px' }}>
                                <Select
                                  className="assigneeDropDown"
                                  placeholder={t('page.select_assignee_placeholder')}
                                  options={options1.sort((a, b) =>
                                    a.label.localeCompare(b.label, 'en', { numeric: true })
                                  )}
                                  onChange={(selectedOptions) => {
                                    formik.setFieldValue('assigned_user_id', selectedOptions);
                                  }}
                                  value={formik.values.assigned_user_id}
                                />
                              </div>
                            </Col>
                            <Col lg={1} xs={1}>
                              <TNButton
                                className="saveAssignee"
                                type="submit"
                                style={{ float: 'right' }}>
                                <FontAwesomeIcon isdirtyform={1} icon={faFloppyDisk} />
                              </TNButton>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </>
                )}
                <br />
                <h5
                  className="react_quill_content_data"
                  dangerouslySetInnerHTML={{ __html: taskDetails.description }}></h5>
              </Col>
            </Row>
            {shouldShowRoute('projects.task.comment.index') ? (
              <Row className="subCommentsHistoryTabs">
                <Col lg={12} xs={12}>
                  {shouldShowRoute('projects.task.comment.index') && (
                    <span
                      onClick={() => {
                        setActiveTab(1);
                      }}
                      className={activeTab == 1 ? 'active' : ''}>
                      Comments
                    </span>
                  )}
                  {shouldShowRoute('projects.task.workLog.index') && (
                    <span
                      onClick={() => {
                        setActiveTab(2);
                      }}
                      style={{ marginRight: activeTab == 2 && '3px' }}
                      className={activeTab == 2 ? 'active' : ''}>
                      Work Logs
                    </span>
                  )}
                  {shouldShowRoute('projects.task.workLog.store') && activeTab == 2 && (
                    <button
                      onClick={handleShow}
                      style={{ paddingLeft: '0px' }}
                      className="work-log-button">
                      ({t('page.add_log')})
                    </button>
                  )}
                  {shouldShowRoute('projects.task.history.index') && (
                    <span
                      onClick={() => {
                        setActiveTab(3);
                      }}
                      className={activeTab == 3 ? 'active' : ''}>
                      Work History
                    </span>
                  )}
                  <Row>
                    {shouldShowRoute('projects.task.comment.index') &&
                      activeTab == 1 &&
                      userData && (
                        <>
                          <ListCommentPage
                            t={t}
                            getProfileImageURL={getProfileImageURL}
                            getImageName={getImageName}
                            shouldShowRoute={shouldShowRoute}
                            userData={userData}
                            setPopupImage={setPopupImage}
                            popupImage={popupImage}
                            socket={socket}
                            projectTeamMembers={projectTeamMembers}
                          />
                        </>
                      )}
                    {
                      // work log tab
                      shouldShowRoute('projects.task.workLog.index') && activeTab == 2 && (
                        <ListWorkLogPage
                          t={t}
                          socket={socket}
                          userData={userData}
                          getImageName={getImageName}
                          show={show}
                          setShow={setShow}
                          handleShow={handleShow}
                          handleClose={handleClose}
                          shouldShowRoute={shouldShowRoute}
                          editShow={editShow}
                          setEditShow={setEditShow}
                          handleEditShow={handleEditShow}
                          handleEditClose={handleEditClose}
                          getProfileImageURL={getProfileImageURL}
                          projectTeamMembers={projectTeamMembers}
                        />
                      )
                    }
                    {shouldShowRoute('projects.task.history.index') && activeTab == 3 && (
                      <ListHistoryPage
                        t={t}
                        socket={socket}
                        getImageName={getImageName}
                        getProfileImageURL={getProfileImageURL}
                        projectTeamMembers={projectTeamMembers}
                      />
                    )}
                    {popupImage && (
                      <Lightbox
                        hideDownload={true}
                        medium={popupImage}
                        large={popupImage}
                        alt="Comment Box Image"
                        onClose={() => {
                          setPopupImage(null);
                        }}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
        </Card>
      )}
    </>
  );
};
ViewTaskPage.propTypes = {
  t: PropTypes.func
};
export default ViewTaskPage;
