import client from '@libs/HttpClient';
class AttendanceService {
  //Attendance Master
  static listAttendance(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const selectedStatus = request.queryKey[3];
    const selectedEmployee = request.queryKey[4];
    const selectedApprover = request.queryKey[5];
    const selectedType = request.queryKey[6];
    const selectedDateRange = request.queryKey[7];
    const id = request.queryKey[8] || '';
    return client.get(
      `/attendance?search=${searchText}&page=${page_no}&user_id=${id}&selectedStatus=${selectedStatus}&selectedEmployee=${selectedEmployee}&selectedApprover=${selectedApprover}&selectedType=${selectedType}&selectedDateRange=${selectedDateRange}`,
      request
    );
  }
  static addAttendance(request) {
    return client.post(`/attendance`, request);
  }
  static getAttendanceUsers(request) {
    return client.get(`/attendance/user-list`, request);
  }
  static useGetAttendanceTypeList(request) {
    return client.get(`/attendance/type-list`, request);
  }
  static viewAttendance(params) {
    return client.get(`/attendance/${params}/edit`);
  }
  static updateAttendance(request) {
    return client.put(`/attendance/${request.id}`, request);
  }
  static deleteAttendance(request) {
    console.log('request:', request);
    return client.post(`/attendance/cancel/${request.id}`, request);
  }
  static approveOrRejectAttendance(request) {
    return client.post(`/attendance/approve-reject/${request.id}`, request);
  }
  static approverList(request) {
    const get_all = request.queryKey[1] ?? 0;
    return client.get(`/leave-approver-list?all=${get_all}`, request);
  }
  static leaveEmployeeList(request) {
    const get_all = request.queryKey[1] ?? 0;
    return client.get(`/leave-employee-list?all=${get_all}`, request);
  }
}
export { AttendanceService };
