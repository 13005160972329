import React from 'react';
import { Helmet } from 'react-helmet';

const CommonSEOTitle = ({ title }) => {
  return (
    <Helmet>
      <title>Technource ERP | {title}</title>
    </Helmet>
  );
};

export default CommonSEOTitle;
