// React Dependencies
import { useState, React, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { Row, Col } from 'react-bootstrap';
// hooks
import { useListTaskHistory } from '@hooks';
// Asset
import '@assets/scss/page/_taskView.scss';

import RenderLogContent from './RenderLogContent';

const ListHistoryPage = ({ t, socket, getImageName, getProfileImageURL }) => {
  const [historyList, setHistoryList] = useState([]);
  // Page number for work log
  const [historyPage, setHistoryPage] = useState(1);
  const [hasMoreHistory, setHasMoreHistory] = useState(true);

  // Project ID And Task ID
  let { id, projectTaskId } = useParams();

  // Socket Initialization
  useEffect(() => {
    socket.on('get-latest-task-history', () => {
      setHistoryPage(1);
      refetchHistory();
    });
  }, []);
  // Fetch Records
  const {
    refetch: refetchHistory,
    isLoading: historyLoading,
    isFetching
  } = useListTaskHistory(
    [id, projectTaskId, historyPage],
    (res) => {
      if (res?.data?.pagination.current_page == res?.data?.pagination.last_page) {
        setHasMoreHistory(false);
      } else {
        setHasMoreHistory(true);
      }
      if (res?.data?.pagination.current_page == 1) {
        setHistoryList(res?.data?.project_task_history);
      } else {
        setHistoryList((prevData) => [...prevData, ...res?.data?.project_task_history]);
      }
    },
    (error) => {
      console.log(error, 'Error');
    },
    {
      staleTime: 0, // Make data stale immediately
      cacheTime: 0 // Optional: clear cache right away
    }
  );

  useEffect(() => {
    refetchHistory();
  }, [historyPage]);

  const loadMore = () => {
    if (hasMoreHistory && !historyLoading && !isFetching) {
      setHistoryPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <Col className="tabDiv" lg={12} xs={12}>
        {historyList.map((log, ind) => (
          <Row key={ind} style={{ marginBottom: '15px' }} className="historyLogClg">
            <Col lg={12} xs={12}>
              <Row>
                <Col lg={1} xs={1} sm={1} style={{ width: '4%' }}>
                  {log?.user?.profile_image && log?.user?.profile_image != '' ? (
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={log?.user?.full_name}
                      className="profileImageWithImage">
                      <img src={getProfileImageURL(log?.user?.user_id, log?.user?.profile_image)} />
                    </div>
                  ) : (
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={log?.user?.full_name}
                      className="profileImageWithoutImage">
                      {getImageName(log?.user?.first_name, log?.user?.last_name)}
                    </div>
                  )}
                </Col>
                <Col
                  lg={11}
                  xs={11}
                  sm={11}
                  style={{ paddingTop: '4px' }}
                  className="react_quill_content_data">
                  {/* {log.log_message} */}
                  <p dangerouslySetInnerHTML={{ __html: log.log_message }}></p>
                </Col>
              </Row>
              <Row>
                <Col lg={1} xs={1} sm={1} style={{ width: '4%' }}></Col>
                <Col
                  lg={11}
                  xs={11}
                  sm={11}
                  className={[2, 6, 7].includes(log.history_type) ? 'profile_change_log' : ''}>
                  <RenderLogContent log={log} />
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
        {(historyLoading || isFetching) && <p>{t('page.loading')}</p>}
        {hasMoreHistory && !(historyLoading || isFetching) && (
          <button
            className="work-log-button-load_more"
            onClick={loadMore}
            disabled={historyLoading || isFetching}>
            {t('page.load_more')}
          </button>
        )}
        {historyList.length <= 0 && !historyLoading && !isFetching && (
          <p>{t('page.history_data_not_found')}</p>
        )}
      </Col>
    </>
  );
};
ListHistoryPage.propTypes = {
  t: PropTypes.func,
  getImageName: PropTypes.func,
  getProfileImageURL: PropTypes.func
};
export default ListHistoryPage;
