import { useMutation, useQuery } from 'react-query';
import { MasterService } from '@api/MasterService';
import { onDefaultError } from '../helpers/commonHelpers';
// Translation Master
// Create
const useAddTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addTranslation, {
    onSuccess,
    onError
  });
};
// Records
const useListTranslation = ([page_no, searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-list', page_no, searchText], MasterService.listTranslation, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Sync
const useSyncTranslation = ([isEnabled], onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-sync'], MasterService.syncTranslation, {
    onSuccess,
    onError,
    enabled: isEnabled,
    retry: 0
  });
};
// Details
const useViewTranslation = (translation_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-view', translation_id], MasterService.viewTranslation, {
    onSuccess,
    onError
  });
};
// Update
const useUpdateTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateTranslation, {
    onSuccess,
    onError
  });
};
// Email Template
// Email Template Master
const useListEmailTemplate = ([page_no, searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(['email-template-list', page_no, searchText], MasterService.listEmailTemplate, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Add Email Template
const useAddEmailTemplate = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addEmailTemplate, {
    onSuccess,
    onError
  });
};
// Update Email Template
const useUpdateEmailTemplate = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateEmailTemplate, {
    onSuccess,
    onError
  });
};
// Email Template Details
const useViewEmailTemplate = (email_template_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['email-template-view', email_template_id], MasterService.viewEmailTemplate, {
    onSuccess,
    onError
  });
};
// FAQ
// Records
const useListFaq = ([page_no, searchText, status, faq_category_id], onSuccess, onError = onDefaultError ) => {
  return useQuery(['faq-list', page_no, searchText, status, faq_category_id], MasterService.listFaq, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};
// Create
const useAddFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addFaq, {
    onSuccess,
    onError
  });
};
// Details
const useViewFaq = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('faq-view', () => MasterService.viewFaq(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateFaq, {
    onSuccess,
    onError
  });
};
// Status Update
const useStatusChangeFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.statusChangeFaq, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteFaq = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.deleteFaq, {
    onSuccess,
    onError
  });
};
// Category Master
// Records
const useGetCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('faq-get-category', MasterService.getFaqCategory, {
    onSuccess,
    onError
  });
};
// Filtered Records
const useGetCategoryListFilter = (onSuccess, onError = onDefaultError) => {
  return useQuery('faq-get-category-list', MasterService.getFaqCategoryList, {
    onSuccess,
    onError
  });
};
// FAQ Category Master
// Records
const useListFaqCategory = ([page_no, searchText, status], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['faq-category-list', page_no, searchText, status],
    MasterService.listFaqCategory,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Create
const useAddFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addFaqCategory, {
    onSuccess,
    onError
  });
};
// Details
const useViewFaqCategory = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('faq-category-view', () => MasterService.viewFaqCategory(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateFaqCategory, {
    onSuccess,
    onError
  });
};
// Status Update
const useStatusChangeFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.statusChangeFaqCategory, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.deleteFaqCategory, {
    onSuccess,
    onError
  });
};
export {
  // Translation Master
  useAddTranslation,
  useListTranslation,
  useViewTranslation,
  useUpdateTranslation,
  useSyncTranslation,
  // Email Template Master
  useListEmailTemplate,
  useAddEmailTemplate,
  useUpdateEmailTemplate,
  useViewEmailTemplate,
  // FAQ Master
  useListFaq,
  useAddFaq,
  useViewFaq,
  useUpdateFaq,
  useDeleteFaq,
  useGetCategoryListFilter,
  useGetCategoryList,
  useStatusChangeFaq,
  // FAQ Category Master
  useListFaqCategory,
  useAddFaqCategory,
  useViewFaqCategory,
  useUpdateFaqCategory,
  useDeleteFaqCategory,
  useStatusChangeFaqCategory
};