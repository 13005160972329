import client from '@libs/HttpClient';
class TaskService {
  static listTask(request) {
    const projectId = request.id;
    const page_no = request.page_no;
    const searchText = request.searchText;
    const selectedPriority = request.selectedPriority;
    const selectedAssignee = request.selectedAssignee;
    const selectedSprint = request.selectedSprint;
    return client.get(
      `/projects/view/${projectId}/task?search=${searchText}&page=${page_no}&selectedPriority=${selectedPriority}&selectedAssignee=${selectedAssignee}&selectedSprint=${selectedSprint}`,
      request
    );
  }
  static deleteTask(projectId, id) {
    return client.delete(`/projects/view/${projectId}/task/${id}`);
  }
  static addTask(request) {
    const projectId = request.project_id;
    return client.post(`projects/view/${projectId}/task`, request);
  }
  static updateTask(request) {
    const id = request.id;
    const projectId = request.project_id;
    return client.put(`/projects/view/${projectId}/task/${id}`, request);
  }
  static viewTask(projectId, id) {
    return client.get(`/projects/view/${projectId}/task/${id}/edit`);
  }
  static getSprintList(projectId) {
    return client.get(`/get-active-sprint-list/${projectId}`);
  }
  static getTaskPriorities() {
    return client.get(`/get-task-priorities`);
  }
  static getTaskStatusTypes(projectId) {
    return client.get(`/get-project-task-status-types/${projectId}`);
  }
  static getTaskDetails(projectId, id) {
    return client.get(`/projects/view/${projectId}/task/${id}/details`);
  }
  static getAssignedProjectEmployeeList(projectId, status = 0) {
    return client.get(`/get-project-assigned-project-employee-list/${projectId}?status=${status}`);
  }
  static updateTaskAssignees(request) {
    const id = request.id;
    const projectId = request.project_id;
    return client.put(`/projects/view/${projectId}/task/${id}/update-assignee`, request);
  }
  static getTaskStatusTypesWithSubTasks(projectId) {
    return client.get(`/get-project-task-status-types-with-sub-tasks/${projectId}`);
  }
  static updateTaskStatus(request) {
    const projectId = request.project_id;
    const taskId = request.task_id;
    return client.put(`/projects/view/${projectId}/task/${taskId}/update-task-status`, request);
  }
}
export { TaskService };
