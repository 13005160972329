import client from '@libs/HttpClient';
class ProjectService {
  //Project Master
  static listProject(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const managerID = request.queryKey[4] || '';
    return client.get(
      `/projects/?search=${searchText}&page=${page_no}&status=${status}&manager_id=${managerID}`,
      request
    );
  }
  static addProject(request) {
    return client.post(`/projects`, request);
  }
  static viewProject(params) {
    return client.get(`/projects/${params}/edit`);
  }
  static updateProject(request) {
    return client.put(`/projects/${request.id}`, request);
  }
  static deleteProject(request) {
    return client.delete(`/projects/${request.id}`, request);
  }
  static employeeList(request) {
    const get_all = request.queryKey[1] ?? 0;
    return client.get(`/employee-list?all=${get_all}`, request);
  }
  static clientList(request) {
    const get_all = request.queryKey[1] ?? 0;
    return client.get(`/client-list?all=${get_all}`, request);
  }
  static projectManagerList(request) {
    return client.get(`/project-manager-list`, request);
  }
}
export { ProjectService };