// React Dependencies
import { useState, useMemo, React, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { Form, Row, Col, Button } from 'react-bootstrap';
import { TNTable, TNButton } from '@common/components';
import { ProjectNavigation } from "../Navigation/ProjectNavigation";
// API
import { useRouteAccessList, useListTaskStatusType, useDeleteTaskStatusType, useStatusChangeTaskStatusType } from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from "../../../../helpers/CommonSEOTitle";
const ListTaskStatusTypePage = ({ t }) => {
  // Navigate
  const navigate = useNavigate();
  // Project ID From Prop
  let { id } = useParams();
  // States
  const [currentPage, setCurrentPage] = useState(localStorage.adminTaskStatusType !== undefined && localStorage.adminTaskStatusType !== ''
    ? JSON.parse(localStorage.adminTaskStatusType).currentPage
    : 1);
  const [paginationData, setPaginationData] = useState(null);
  const [data, setData] = useState([]);
  const [routeName, setRouteNames] = useState([]);
  const [searchText, setSearchText] = useState(localStorage.adminTaskStatusType !== undefined && localStorage.adminTaskStatusType !== ''
    ? JSON.parse(localStorage.adminTaskStatusType).searchText
    : '');
  // Route
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  const addTaskStatusType = () => {
    navigate(`/projects/view/${id}/task-status-type/add`);
  }
  // On Load Set Filter
  useEffect(() => {
    let adminTaskStatusType = {
      id: id,
      searchText: searchText,
      currentPage: currentPage
    };
    localStorage.adminTaskStatusType = JSON.stringify(adminTaskStatusType);
  }, [id, currentPage, searchText]);
  // Get Records
  const { refetch } = useListTaskStatusType([id, currentPage, searchText],
    (res) => {
      setData(res.data.task_status_type_list);
      setPaginationData(res.data.pagination);
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
    }
  );
  const handleSearch = (search_text) => {
    setSearchText(search_text.target.value);
  };
  const handleClearFilter = () => {
    setSearchText('');
    setCurrentPage(1);
    localStorage.removeItem('adminTaskStatusType');
  };
  // Delete Task Status Type
  const { deleteTaskStatusType } = useDeleteTaskStatusType();
  const handleDeleteClick = (id, taskStatusTypeId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
            <div className="alert-popup">
              <h2>{t('page.delete_task_status_type_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTaskStatusType(id, taskStatusTypeId);
                    if (response && response.message) {
                      toast.success(response.message);
                    }
                    onClose();
                    refetch();
                  } catch (error) {
                    toast.error(error);
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      },
    });
  }
  const handleEditClick = (id, taskStatusTypeId) => {
    navigate(`/projects/view/${id}/task-status-type/${taskStatusTypeId}/edit`);
  };
  // Status
  const { mutate: doUpdateStatus } = useStatusChangeTaskStatusType(
    (res) => {
      toast.success(res.message);
      refetch();
    },
    (err) => {
      toast.error(err);
    }
  );
  const handleStatusClick = (tdata) => {
    let taskStatusTypeID = tdata.currentTarget.getAttribute('id');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
            <div className="alert-popup">
              <h2>{t('page.project_task_status_type_status_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={() => {
                  const values = {};
                  values.id = taskStatusTypeID;
                  values.project_id = id;
                  doUpdateStatus(values);
                  onClose();
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };
  // Table Columns
  const actionColumn = {
    Header: `${t('page.action_column')}`,
    accessor: 'task_status_type_id',
    Cell: ({ value: taskStatusTypeId }) => {
      return (
        <div className="d-inline-flex">
          {shouldShowRoute('projects.taskStatusType.edit') &&
            <TNButton
              className="table-primary-button" onClick={() => handleEditClick(id, taskStatusTypeId)}>
              {t('page.action_button_text_edit')}
            </TNButton>
          }
          {shouldShowRoute('projects.taskStatusType.destroy') &&
            <TNButton className="table-delete-button" onClick={() => handleDeleteClick(id, taskStatusTypeId)}>
              {t('page.action_button_text_delete')}
            </TNButton>
          }
        </div>

      );
    },
    disableSortBy: true,
  }
  let showActionColumn = shouldShowRoute('projects.team.destroy')
  const columnsjson = [
    {
      Header: `${t('page.project_task_status_type_name')}`,
      accessor: 'status_name',
      disableSortBy: true,
    },
    {
      Header: `${t('page.project_task_status_type_sequence')}`,
      accessor: 'sequence',
      disableSortBy: true,
    },
    {
      Header: `${t('page.project_task_status_type_type')}`,
      accessor: 'is_active',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          shouldShowRoute('projects.taskStatusType.update-status') ? (
            <div>
              <span
                id={row.original.task_status_type_id}
                style={{ cursor: "pointer" }}
                className={row.original.is_active === 'Active' ? 'status-active' : 'status-inactive'}
                onClick={shouldShowRoute('projects.taskStatusType.update-status') && handleStatusClick.bind(this)}
              >
                {row.original.is_active === 'Active' ? 'active' : 'Inactive'}
              </span>
            </div>
          ) : (
            row.original.is_active === 'Active' ? 'active' : 'Inactive'
          )
        );
      }
    },
  ];
  if (showActionColumn) {
    columnsjson.push(actionColumn);
  }
  const columns = useMemo(() => columnsjson, [routeName]);
  if (paginationData === null) {
    return null;
  }
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
    localStorage.setItem("currentPage", pageIndex + 1);
  };
  return (
    <>
    <CommonSEOTitle
        title={t('page.project_task_status_type_tab')}
      />
      <ProjectNavigation t={t} id={id} activeLabel='Task Status Type' />
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder="Search"
                onKeyUp={handleSearch}
                onChange={handleSearch}
                className="filter-column form-field"
                value={searchText}
              />
            </Form.Group>
            <Button type="button" onClick={handleClearFilter} className="secondary-remove-button" disabled={(searchText) ? false : true}>
              {t('page.cancel_search_button')}
            </Button>
            {
              shouldShowRoute('projects.taskStatusType.store') && (
                <TNButton
                  className="table-add-button"
                  loading={false}
                  type="button"
                  onClick={addTaskStatusType}
                >
                  {t('page.task_status_type_add_button')}
                </TNButton>
              )
            }
          </div>
        </Col>
      </Row>
      <div style={{ clear: "both", marginTop: '20px' }}>
        <TNTable
          columns={columns}
          data={data}
          paginationData={paginationData}
          onSelectPage={pageIndexHandle}
          t={t}
          pageIndexGet={currentPage - 1}
          key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        />
      </div>
    </>
  )
}
ListTaskStatusTypePage.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func,
  row: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.any,
};
export default ListTaskStatusTypePage;