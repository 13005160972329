// React Dependencies
import { useState, React, useEffect, useRef } from 'react';
// Common Dependencies
import { useParams, useNavigate } from 'react-router-dom';
// Component
import { Button, Form, Card, Row, Col } from 'react-bootstrap';

import { TNButton } from '@common/components';
import validationSchema from './AddEditWorkLogValidation';

import PropTypes from 'prop-types';
// Modal Component
import Modal from 'react-bootstrap/Modal';
// API
import { useUpdateTaskWorkLog } from '@hooks';
// Validation
import { useFormik } from 'formik';
// Helper
import { defaultValue } from '@helpers';
// Alert
import { toast } from 'react-toastify';
import CustomReactQuillEditor from '../../../../../common/components/CustomReactQuillEditor';

// Font Awesome
const EditWorkLogPopup = ({
  t,
  socket,
  refetch,
  workLogPage,
  setWorkLogPage,
  handleEditClose,
  editShow,
  editWorkLog,
  projectTeamMembers
}) => {
  const [descriptionD, setDescriptionD] = useState(editWorkLog.description);
  const reactQuillRef = useRef(null);

  // Update Log API
  const { mutate: doUpdateWorkLog, isLoading } = useUpdateTaskWorkLog(
    (response) => {
      toast.success(response.message);
      formik.resetForm();
      handleEditClose();
      if (workLogPage == 1) {
        refetch();
      } else {
        setWorkLogPage(1);
      }
      socket.emit('worklog-updated');
    },
    (error) => {
      if (Array.isArray(error.message)) {
        error.message.map((er) => {
          toast.error(er);
        });
      } else {
        toast.error(error.message);
      }
    }
  );
  // Validation And Submit
  const formik = useFormik({
    initialValues: {
      project_task_work_log_id: editWorkLog.project_task_work_log_id,
      project_id: editWorkLog.project_id,
      project_task_id: editWorkLog.project_task_id,
      time_spent_in_hour: editWorkLog.time_spent_in_hour,
      time_spent_in_minute: editWorkLog.time_spent_in_minute,
      work_log_date: editWorkLog.formatted_work_log_date,
      description: editWorkLog.description
    },
    validationSchema,
    onSubmit: (values) => {
      doUpdateWorkLog(values);
    }
  });

  return (
    <>
      <Modal show={editShow} onHide={handleEditClose}>
        <Modal.Header closeButton>{t('page.edit_time_tracking')}</Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12} xs={12}>
                {/* <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.time_spent')}
                  </Form.Label>
                  <Form.Control
                    className={
                      ' ' +
                      (formik.touched.time_spent && formik.errors.time_spent
                        ? 'form-field-error'
                        : formik.touched.time_spent && !formik.errors.time_spent
                          ? 'form-field-success'
                          : '')
                    }
                    type="text"
                    name="time_spent"
                    placeholder={t('page.task_time_spent_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.time_spent}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.time_spent && formik.errors.time_spent ? (
                      <div>{t(formik.errors.time_spent)}</div>
                    ) : null}
                  </div>
                </Form.Group> */}

                <label className="field-label form-label" style={{ marginBottom: '0px' }}>
                  {t('page.time_spent')}
                </label>
                <Row>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {/* <Form.Label className="field-label">
                        {t('page.time_spent_hour')}
                      </Form.Label> */}
                      <Form.Control
                        className={
                          ' ' +
                          (formik.touched.time_spent_in_hour && formik.errors.time_spent_in_hour
                            ? 'form-field-error'
                            : formik.touched.time_spent_in_hour && !formik.errors.time_spent_in_hour
                              ? 'form-field-success'
                              : '')
                        }
                        type="text"
                        name="time_spent_in_hour"
                        placeholder={t('page.task_time_spent_hour_placeholder')}
                        onBlur={formik.handleBlur}
                        value={formik.values.time_spent_in_hour}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (event.target.value === '' || re.test(event.target.value)) {
                            formik.setFieldValue('time_spent_in_hour', event.target.value);
                          }
                        }}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.time_spent_in_hour && formik.errors.time_spent_in_hour ? (
                          <div>{t(formik.errors.time_spent_in_hour)}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {/* <Form.Label className="field-label">
                        {t('page.time_spent_minute')}
                      </Form.Label> */}
                      <Form.Control
                        className={
                          ' ' +
                          (formik.touched.time_spent_in_minute && formik.errors.time_spent_in_minute
                            ? 'form-field-error'
                            : formik.touched.time_spent_in_minute &&
                                !formik.errors.time_spent_in_minute
                              ? 'form-field-success'
                              : '')
                        }
                        type="text"
                        name="time_spent_in_minute"
                        placeholder={t('page.task_time_spent_minute_placeholder')}
                        onBlur={formik.handleBlur}
                        value={formik.values.time_spent_in_minute}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (event.target.value === '' || re.test(event.target.value)) {
                            formik.setFieldValue('time_spent_in_minute', event.target.value);
                          }
                        }}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.time_spent_in_minute &&
                        formik.errors.time_spent_in_minute ? (
                          <div>{t(formik.errors.time_spent_in_minute)}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.work_log_date_label')}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="work_log_date"
                    placeholder={t('page.work_log_date_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.work_log_date}
                    onClick={(event) => {
                      event.target.showPicker();
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.work_log_date && formik.errors.work_log_date ? (
                      <div>{t(formik.errors.work_log_date)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.description')}
                  </Form.Label>
                  <CustomReactQuillEditor
                    t={t}
                    ref={reactQuillRef}
                    value={descriptionD}
                    fileUploadDir="project/task/work_log"
                    taggingUsers={projectTeamMembers}
                    onChange={(content) => {
                      setDescriptionD(content);
                      formik.setFieldValue('description', content);
                    }}
                    placeholder={t('page.react-quill-placeholder')}
                    onFocus={() => console.log('Editor focused')}
                    onBlur={() => console.log('Editor blurred')}
                  />

                  <div className="form-field-error-text">
                    {formik.touched.description && formik.errors.description ? (
                      <div>{t(formik.errors.description)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <div className="m-2" style={{ float: 'right' }}>
              <Button variant="danger" onClick={handleEditClose} style={{ marginRight: '5px' }}>
                {t('page.time_tracking_popup_cancle_btn')}
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={isLoading}
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                {t('page.time_tracking_popup_save_btn')}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditWorkLogPopup;
