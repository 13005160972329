// React Dependencies
import { useState, React } from "react";
// Common Dependencies
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { Button, Form, Card, Row, Col } from 'react-bootstrap';
import { TNBreadCurm, TNButton } from '@common/components';
import Select from 'react-select';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
// API
import { useUpdateSprint, useEditSprint, useGetSprintStatusTypes } from '@hooks';
// Validation
import { useFormik } from 'formik';
import validationSchema from './AddEditSprintValidation';
// Helper
import { defaultValue } from '@helpers';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from "../../../../helpers/CommonSEOTitle";
const EditSprintPage = ({ t }) => {
  // Navigate
  const navigate = useNavigate();
  // Project ID
  let { id, projectSprintId } = useParams();
  // Sprint Types
  const { data: optionList, isLoading: isSprintStatusTypeLoading } = useGetSprintStatusTypes();
  let options = [];
  if (optionList !== undefined && !isSprintStatusTypeLoading) {
    Object.entries(optionList).map(([value, label]) => {
      options.push({ value: parseInt(value), label: label });
      return true;
    });
  }
  // Edit Sprint API Call
  const { mutate: doEditSprint } = useUpdateSprint((response) => {
    toast.success(response.message);
    formik.resetForm();
    navigate(`/projects/view/${id}/sprint`);
  });
  // On Load Initialize Values
  const { isLoading } = useEditSprint(id, projectSprintId, ({ data: sprintData }) => {
    if (sprintData) {
      formik.values.name = sprintData.name;
      formik.values.status = sprintData.status;
      formik.values.start_date = sprintData.start_date;
      formik.values.end_date = sprintData.end_date;
      setStartDate(sprintData.start_date);
      setEndDate(sprintData.end_date);
    }
  },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }else{
        toast.error(error.message);
      }
    });
  // Formik Validation Set And Submit
  const formik = useFormik({
    initialValues: {
      project_id: id,
      id: projectSprintId,
      name: '',
      start_date: '',
      end_date: '',
      status: '',
    },
    validationSchema,
    onSubmit: (values) => {
      doEditSprint(values);
    },
  });
  // Cancel Form
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => { onClose(); }} />
              <div className="alert-popup">
                <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
                <Button className="table-delete-button" onClick={() => { onClose(); navigate(`/projects/view/${id}/sprint`); }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(`/projects/view/${id}/sprint`);
    }
  };
  // States
  const [startDate, setStartDate] = useState(formik.values.start_date);
  const [endDate, setEndDate] = useState(formik.values.end_date);
  // Start Date And End Date Handler
  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    formik.values.start_date = newStartDate;
    setStartDate(newStartDate);
    // if (newStartDate > endDate) {
    //   setEndDate(newStartDate);
    // }
  };
  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    formik.values.end_date = newEndDate;
    if (newEndDate >= startDate || newEndDate.trim() == '') {
      setEndDate(newEndDate);
    }
  };
  return (
    <>
     <CommonSEOTitle
        title={t('page.project_sprint_edit_title')}
      />
      <TNBreadCurm
        breadcurmArray={[
          {
            label: t('page.project_list_label'),
            link: '/projects/list',
          },
          {
            label: t('page.view_project_label'),
            active: 'active',
          },
        ]}
      />
      <h1>{t('page.view_project_label')}</h1>
      <ProjectNavigation t={t} id={id} activeLabel='Sprint' />
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.project_sprint_edit_title')}</h1>
        <div className="edit-profile-form">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">{t('page.sprint_name_label')}</Form.Label>
                  <Form.Control
                    className={
                      ' ' +
                      (formik.touched.name && formik.errors.name
                        ? 'form-field-error'
                        : formik.touched.name && !formik.errors.name
                          ? 'form-field-success'
                          : '')
                    }
                    type="text"
                    name="name"
                    placeholder={t('page.sprint_name_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.name && formik.errors.name ? (
                      <div>{t(formik.errors.name)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={6}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.start_date_label')}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    placeholder={t('page.start_date_placeholder')}
                    onBlur={formik.handleBlur}
                    value={startDate}
                    max={endDate}
                    onChange={handleStartDateChange}
                    onClick={(event) => { event.target.showPicker() }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} xs={6}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.end_date_label')}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="end_date"
                    placeholder={t('page.end_date_placeholder')}
                    onBlur={formik.handleBlur}
                    value={endDate}
                    min={startDate}
                    onChange={handleEndDateChange}
                    onClick={(event) => { event.target.showPicker() }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.sprint_status_type_label')}
                  </Form.Label>
                  <Select
                    placeholder={t('page.sprint_status_type_placeholder')}
                    options={options}
                    className={
                      ' ' +
                      (formik.touched.status && formik.errors.status
                        ? 'form-select-error'
                        : formik.touched.status && !formik.errors.status
                          ? 'form-select-success'
                          : '')
                    }
                    value={defaultValue(options, formik.values.status)}
                    onChange={(selectedOption) => {
                      formik.setFieldValue('status', selectedOption.value);
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.status && formik.errors.status ? (
                      <div>{t(formik.errors.status)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancel}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                type="submit"
                loading={isLoading}
                isdirtyform={1}>
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </div>
      </Card>
    </>
  );
};
EditSprintPage.propTypes = {
  t: PropTypes.func,
};
export default EditSprintPage;