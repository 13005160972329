import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrivateLayout } from '../common/layouts';
import Loader from '@common/components/Loader';
import { loader } from '../store/features/loaderSlice';
import { loggedUser } from '../store/features/authSlice';
const PrivateRoute = (props) => {
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminLoggedIn = checkLoggedInUser.isAdminLoggedIn;
  window.addEventListener('storage', (e) => {
    if (e.isLogout !== undefined && e.isLogout !== '' && e.isLogout === 1) {
      navigate('/login');
    }
  });
  return isAdminLoggedIn ? (
    <PrivateLayout>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: { from: location }
      }}
    />
  );
};
export default PrivateRoute;
