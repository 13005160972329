// React Dependencies
import React, { useState, useEffect } from 'react';
// Common Dependencies
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faClose } from '@fortawesome/free-solid-svg-icons';
// Component
import { Container, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
// Validation
import { useFormik } from 'formik';
// Alert
import { toast } from 'react-toastify';
import validationSchema from './ResetPasswordValidation';
import { confirmAlert } from 'react-confirm-alert';
// API
import { useResetPassword } from '@hooks';
// Page Component
import { PublicLayout, LogoUrl } from '@adminlayout';
import { TNButton } from '@common/components';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
const ResetPasswordPage = ({ t }) => {
  // Navigate
  const navigate = useNavigate();
  // Reset Password Request
  const {
    mutate: doResetPassword,
    isLoading,
    error: addRPList,
    isError: addRPError
  } = useResetPassword(
    (response) => {
      toast.success(response.message);
      localStorage.reset_password = true;
      localStorage.removeItem('verify_otp_token');
      localStorage.removeItem('reset_password');
      localStorage.removeItem('isAdmin');
      navigate('/login');
    },
    (error) => {
      window.scrollTo(0, 0);
      toast.error(error.message);
    }
  );
  // States
  const [isRevealPwd, setIsRevealPwd] = useState();
  const [isCnfRevealPwd, setCnfIsRevealPwd] = useState();
  // Cancel Reset Password
  const handleCancel = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate(`/login/`);
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        );
      }
    });
  };
  // On Load Add Class To The Body
  useEffect(() => {
    document.body.classList.add('bg-box');
    if (
      localStorage.isAdmin === undefined ||
      localStorage.isAdmin === '' ||
      localStorage.verify_otp_token === undefined ||
      localStorage.verify_otp_token === ''
    ) {
      navigate('/login');
    }
  });
  // Validate And Submit Request
  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema,
    onSubmit: (values) => {
      values.user_id = localStorage.verify_otp_token;
      doResetPassword(values);
    }
  });
  return (
    <>
      <CommonSEOTitle title={t('page.reset_password_header_text')} />
      <PublicLayout>
        <div className="auth-pages">
          <Container>
            <div className="background-box">
              <div className="admin-login">
                <div className="brand-logo">
                  <LogoUrl />
                </div>
                <div>
                  <h1 className="page-heading-center">{t('page.reset_password_header_text')}</h1>
                </div>
                <div>
                  {/* Reset Password Form */}
                  <Form onSubmit={formik.handleSubmit}>
                    <div>
                      <Form.Label className="field-label field-label-top">
                        {t('page.reset_password_password_label')}
                      </Form.Label>
                      <InputGroup className="form-group-field">
                        <FormControl
                          className={
                            '' +
                            (formik.touched.password && formik.errors.password
                              ? 'form-field-error'
                              : formik.touched.password && !formik.errors.password
                                ? 'form-field-success'
                                : '')
                          }
                          name="password"
                          placeholder={t('page.reset_password_password_placeholder')}
                          autoComplete="off"
                          type={isRevealPwd ? 'text' : 'password'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <InputGroup.Text
                          className={
                            '' +
                            (formik.touched.password && formik.errors.password
                              ? 'form-field-error'
                              : formik.touched.password && !formik.errors.password
                                ? 'form-field-success'
                                : '')
                          }
                          id="basic-addon1">
                          <FontAwesomeIcon
                            onClick={() => setIsRevealPwd((prevState) => !prevState)}
                            icon={isRevealPwd ? faEye : faEyeSlash}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <div className="form-field-error-text">
                        {formik.touched.password && formik.errors.password ? (
                          <div>{t(formik.errors.password)}</div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <Form.Label className="field-label field-label-top">
                        {t('page.reset_password_confirm_password_label')}
                      </Form.Label>
                      <InputGroup className="form-group-field">
                        <FormControl
                          className={
                            '' +
                            (formik.touched.confirm_password && formik.errors.confirm_password
                              ? 'form-field-error'
                              : formik.touched.confirm_password && !formik.errors.confirm_password
                                ? 'form-field-success'
                                : '')
                          }
                          name="confirm_password"
                          placeholder={t('page.reset_password_confirm_password_placeholder')}
                          autoComplete="off"
                          type={isCnfRevealPwd ? 'text' : 'password'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirm_password}
                        />
                        <InputGroup.Text
                          className={
                            '' +
                            (formik.touched.confirm_password && formik.errors.confirm_password
                              ? 'form-field-error'
                              : formik.touched.confirm_password && !formik.errors.confirm_password
                                ? 'form-field-success'
                                : '')
                          }>
                          <FontAwesomeIcon
                            onClick={() => setCnfIsRevealPwd((prevState) => !prevState)}
                            icon={isCnfRevealPwd ? faEye : faEyeSlash}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <div className="form-field-error-text">
                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                          <div>{t(formik.errors.confirm_password)}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="primary-button">
                      <span className="link-center" onClick={handleCancel}>
                        {t('page.reset_password_cancel_button_text')}
                      </span>
                      <TNButton
                        type="submit"
                        loading={isLoading}
                        isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                        {t('page.reset_password_submit_button_text')}
                      </TNButton>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </PublicLayout>
    </>
  );
};
ResetPasswordPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { ResetPasswordPage };
