import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ListHolidaysEtcPage = React.lazy(() => import('./ListHolidaysEtcPage'));
const AddHolidaysEtcPage = React.lazy(() => import('./AddHolidaysEtcPage'));
const EditHolidaysEtcPage = React.lazy(() => import('./EditHolidaysEtcPage'));
const HolidaysEtcRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListHolidaysEtcPage t={t} />} />
      <Route exact path="/add" element={<AddHolidaysEtcPage t={t} />} />
      <Route exact path='/edit/:id' element={<EditHolidaysEtcPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
HolidaysEtcRoute.propTypes = {
  t: PropTypes.func
};
export default HolidaysEtcRoute;