// React Dependencies
import { useState, useMemo, React, useEffect } from 'react';
// Common Dependencies
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { Form, Row, Col, Button } from 'react-bootstrap';
import { TNTable, TNButton } from '@common/components';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
// API Call
import {
  useRouteAccessList,
  useListSprint,
  useDeleteSprint,
  useGetSprintStatusTypes
} from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
const ListSprintPage = ({ t }) => {
  // Navigate
  const navigate = useNavigate();
  // Project ID
  let { id } = useParams();
  // Sprint Status
  const { data: optionList, isLoading: isSprintStatusTypeLoading } = useGetSprintStatusTypes();
  const sprintTypeOptions = [];
  if (optionList !== undefined && !isSprintStatusTypeLoading) {
    Object.entries(optionList).map(([value, label]) => {
      sprintTypeOptions.push({ value, label });
      return true;
    });
  }
  // States
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminSprintPost !== undefined && localStorage.adminSprintPost !== ''
      ? JSON.parse(localStorage.adminSprintPost).currentPage
      : 1
  );
  const [paginationData, setPaginationData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.adminSprintPost !== undefined && localStorage.adminSprintPost !== ''
      ? JSON.parse(localStorage.adminSprintPost).selectedStatus
      : ''
  );
  const [searchText, setSearchText] = useState(
    localStorage.adminSprintPost !== undefined && localStorage.adminSprintPost !== ''
      ? JSON.parse(localStorage.adminSprintPost).searchText
      : ''
  );
  const [data, setData] = useState([]);
  const [routeName, setRouteNames] = useState([]);
  const [startDate, setStartDate] = useState(
    localStorage.adminSprintPost !== undefined && localStorage.adminSprintPost !== ''
      ? JSON.parse(localStorage.adminSprintPost).startDate
      : ''
  );
  const [endDate, setEndDate] = useState(
    localStorage.adminSprintPost !== undefined && localStorage.adminSprintPost !== ''
      ? JSON.parse(localStorage.adminSprintPost).endDate
      : ''
  );
  // Route
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  const addSprint = () => {
    navigate(`/projects/view/${id}/sprint/add`);
  };
  // On Load Set Filters
  useEffect(() => {
    let adminSprintPost = {
      id: id,
      searchText: searchText,
      currentPage: currentPage,
      selectedStatus: selectedStatus,
      startDate: startDate,
      endDate: endDate
    };
    localStorage.adminSprintPost = JSON.stringify(adminSprintPost);
  }, [id, currentPage, searchText, selectedStatus, startDate, endDate]);
  // On Load Fetch Records
  const { refetch } = useListSprint(
    [id, currentPage, searchText, selectedStatus, startDate, endDate],
    (res) => {
      setData(res.data.project_sprint_list);
      setPaginationData(res.data.pagination);
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  console.log('refetch:', typeof refetch);
  // Remove Sprint
  const { deleteSprint } = useDeleteSprint();
  // Action Handlers
  const handleDeleteClick = (id, sprintId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_task_status_type_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteSprint(id, sprintId);
                    if (response && response.message) {
                      toast.success(response.message);
                    }
                    onClose();
                    refetch();
                  } catch (error) {
                    toast.error(error);
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };
  const handleEditClick = (id, sprintId) => {
    navigate(`/projects/view/${id}/sprint/${sprintId}/edit`);
  };
  // Table Columns
  const actionColumn = {
    Header: `${t('page.action_column')}`,
    accessor: 'project_sprint_id',
    Cell: ({ value: sprintId }) => {
      return (
        <div className="d-inline-flex">
          {shouldShowRoute('projects.sprint.edit') && (
            <TNButton
              className="table-primary-button"
              onClick={() => handleEditClick(id, sprintId)}>
              {t('page.action_button_text_edit')}
            </TNButton>
          )}
          {shouldShowRoute('projects.sprint.destroy') && (
            <TNButton
              className="table-delete-button"
              onClick={() => handleDeleteClick(id, sprintId)}>
              {t('page.action_button_text_delete')}
            </TNButton>
          )}
        </div>
      );
    },
    disableSortBy: true
  };
  let showActionColumn = shouldShowRoute('projects.team.destroy');
  const columnsjson = [
    {
      Header: `${t('page.project_sprint_name')}`,
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: `${t('page.project_sprint_status')}`,
      accessor: 'status_name',
      disableSortBy: true
    },
    {
      Header: `${t('page.project_sprint_start_date')}`,
      accessor: 'formatted_start_date',
      disableSortBy: true
    },
    {
      Header: `${t('page.project_sprint_end_date')}`,
      accessor: 'formatted_end_date',
      disableSortBy: true
    }
  ];
  if (showActionColumn) {
    columnsjson.push(actionColumn);
  }
  const columns = useMemo(() => columnsjson, [routeName]);
  if (paginationData === null) {
    return null;
  }
  // Page Index Handler
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };
  // Filters
  const handleSearch = (search_text) => {
    setSearchText(search_text.target.value);
  };
  const handleClearFilter = () => {
    setSearchText('');
    setSelectedStatus('');
    setStartDate('');
    setEndDate('');
    setCurrentPage(1);
    localStorage.removeItem('adminSprintPost');
  };
  return (
    <>
      <CommonSEOTitle title={t('page.project_sprint_tab')} />
      <ProjectNavigation t={t} id={id} activeLabel="Sprint" />
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder="Search"
                onKeyUp={handleSearch}
                onChange={handleSearch}
                className="filter-column form-field"
                value={searchText}
              />
            </Form.Group>
            <Button
              type="button"
              onClick={handleClearFilter}
              className="secondary-remove-button"
              disabled={searchText || selectedStatus || (startDate && endDate) ? false : true}>
              {t('page.cancel_search_button')}
            </Button>
            {shouldShowRoute('projects.sprint.store') && (
              <TNButton
                className="table-add-button"
                loading={false}
                type="button"
                onClick={addSprint}>
                {t('page.project_sprint_add_button')}
              </TNButton>
            )}
          </div>
        </Col>
      </Row>
      <div style={{ clear: 'both', marginTop: '20px' }}>
        <TNTable
          columns={columns}
          data={data}
          paginationData={paginationData}
          onSelectPage={pageIndexHandle}
          t={t}
          pageIndexGet={currentPage - 1}
          key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        />
      </div>
    </>
  );
};
ListSprintPage.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func,
  row: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.any
};
export default ListSprintPage;
