import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
const AddTableColumnPage = React.lazy(() => import('./AddTableColumnPage'));
const EditTableColumnPage = React.lazy(() => import('./EditTableColumnPage'));
const TableColumnRoute = ({ t }) => {
  return [
    <Route
      key="database_table_column_add"
      exact
      path="/view/:projectId/database/:databaseId/table/:tableId/columns/add"
      element={<AddTableColumnPage t={t} />}
    />,
    <Route
      key="database_table_column_edit"
      exact
      path="/view/:projectId/database/:databaseId/table/:tableId/columns/:id/edit"
      element={<EditTableColumnPage t={t} />}
    />
  ];
};
TableColumnRoute.propTypes = {
  t: PropTypes.func
};
export default TableColumnRoute;
