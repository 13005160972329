import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const ViewUserApprover = React.lazy(() => import('./ViewUserApprover'));
const UserApproverRoutes = ({ t }) => {
  return [
    <Route
      key="view_user_approver"
      exact
      path="/view/:id/approvers"
      element={<ViewUserApprover t={t} />}
    />
  ];
};
UserApproverRoutes.propTypes = {
  t: PropTypes.func
};
export default UserApproverRoutes;
