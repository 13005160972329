// React Dependencies
import { React } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useDispatch } from 'react-redux';
// API
import {
  ForgetPasswordPage,
  LoginPage,
  OtpVerificationPage,
  ResetPasswordPage
} from '../pages/Auth';
import { useGetSettingData } from '@hooks';
// Routes
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
// Components
import { addSetting, settingData } from '../store/features/settingSlice';
import { EditProfilePage } from '../pages/Accounts/EditProfile/EditProfilePage';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { ChangePasswordPage } from '../pages/Accounts/ChangePassword/ChangePasswordPage';
import { GeneralSetting } from '../pages/Setting/GeneralSettingPage';
import { SetPasswordPage } from '../pages/Auth/SetPassword/SetPasswordPage';
import EmailTemplateRoutes from '../pages/Masters/EmailTemplate/EmailTemplateRoutes';
import TranslationRoutes from '../pages/Masters/Translations/TranslationRoutes';
import PageNotFound from '@common/components/PageNotFound';
import FaqRoute from '../pages/Masters/Faq/FaqRoute';
import FaqCategoryRoute from '../pages/Masters/FaqCategory/FaqCategoryRoute';
import RoleRoutes from '../pages/UsersMaster/Roles/RoleRoutes';
import DBFieldTypeRoute from '../pages/Projects/DBFieldType/DBFieldTypeRoute';
import AttendanceRoute from '../pages/HRMS/Attendance/AttendanceRoute';
import ProjectRoute from '../pages/Projects/Project/ProjectRoute';
import UserRoutes from '../pages/UsersMaster/Users/UserRoutes';
import DepartmentRoute from '../pages/HRMS/Department/DepartmentRoute';
import BranchRoute from '../pages/HRMS/Branch/BranchRoute';
import HolidaysEtcRoute from '../pages/HRMS/HolidaysEtc/HolidaysEtcRoute';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: setting }) => {
    const dataStore = {
      dashboard_logo: setting.common_header_logo,
      front_logo: setting.common_login_logo,
      email_logo: setting?.common_email_header_logo,
      favicon_icon: setting?.common_favicon_icon,
      seo_title: setting.seo_title,
      seo_description: setting.seo_description,
      setting_get: false
    };
    dispatch(addSetting(dataStore));
  });
  const getSettingData = useSelector(settingData);
  return (
    <Router basename={'/'}>
      <Helmet>
        <title>Technource ERP</title>
        <meta name="title" content="Technource ERP" />
        <meta name="description" content="Technource ERP" />
        <meta name="og:title" content="Technource ERP" />
        <meta name="og:description" content="Technource ERP" />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="icon" type="image/x-icon" href={`${getSettingData.favicon_icon}`} />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path="/login" element={<LoginPage t={t} />} />
          <Route exact path="/forget-password" element={<ForgetPasswordPage t={t} />} />
          <Route exact path="/otp-verification" element={<OtpVerificationPage t={t} />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage t={t} />} />
          <Route exact path="/set-password/:user_id" element={<SetPasswordPage t={t} />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<DashboardPage t={t} />} />
          <Route exact path="/edit-profile" element={<EditProfilePage t={t} />} />
          <Route exact path="/change-password" element={<ChangePasswordPage t={t} />} />
          {/* Roles Master   */}
          <Route exact path='/roles/*' element={<RoleRoutes t={t} />} />
          {/* user master */}
          <Route exact path='/user/*' element={<UserRoutes t={t} />} />
          {/* {shouldShowRoute('setting.index') && */}
            <Route basename={'/setting/'}>
              <Route exact path="/setting/general" element={<GeneralSetting t={t} />} />
            </Route>
          {/* } */}
          {/* Translations Routes */}
          <Route path="/translations/*" element={<TranslationRoutes t={t} />} />
          {/* Master Email Template Routes */}
          <Route path="/email-template/*" element={<EmailTemplateRoutes t={t} />} />
          {/* Faq Routes */}
          <Route exact path="/faq/*" element={<FaqRoute t={t} />} />
          {/* Faq Category Routes */}
          <Route exact path="/faq-category/*" element={<FaqCategoryRoute t={t} />} />
          {/* DBField Type Route */}
          <Route exact path="/dbfieldtype/*" element={<DBFieldTypeRoute t={t} />} />
          {/* Project Route */}
          <Route exact path="/projects/*" element={<ProjectRoute t={t} />} />
          {/* HRMS Route */}
          <Route exact path="/attendance/*" element={<AttendanceRoute t={t} />} />
          {/* branch route */}
          <Route exact path="/branch/*" element={<BranchRoute t={t} />} />
          {/* Holidays Etc route */}
          <Route exact path="/holidays-etc/*" element={<HolidaysEtcRoute t={t} />} />
          {/* department route */}
          <Route exact path="/department/*" element={<DepartmentRoute t={t} />} />
        </Route>
        <Route>
          <Route path="*" element={<PageNotFound t={t} from="admin" />} />
        </Route>
      </Routes>
    </Router>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func
};
export default PagesRoutes;