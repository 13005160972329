import { useQuery } from 'react-query';
import { OptionService } from '../api/OptionService';
import { onDefaultError } from '../helpers/commonHelpers';
//Database Table Master
const useGetProjectTasksOptions = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-options-project-tasks', [params]],
    () => OptionService.projectTasksOptions(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

export { useGetProjectTasksOptions };
