import * as Yup from 'yup';
let validationSchema = Yup.object({
    name: Yup.string(('page.add_task_name_required')).trim().max(255, ('page.add_task_name_validation_max')).required(
        ('page.add_task_name_required'),
    ),
    sprint_id: Yup.string(('page.add_sprint_required')).required(
        ('page.add_sprint_required')
    ),
    task_status_type_id: Yup.string(('page.add_task_status_type_required')).required(
        ('page.add_task_status_type_required')
    ),
    original_estimate_time: Yup.string(('page.add_original_estimate_time_required')).trim().min(0,('page.add_original_estimate_time_validation_min')).max(255, ('page.add_original_estimate_time_validation_max')).required(
        ('page.add_original_estimate_time_required'),
    ),
    priority: Yup.string(('page.add_priority_required')).required(
        ('page.add_priority_required'),
    ),
    description: Yup.string(('page.add_description_required')).required(
        ('page.add_description_required'),
    ),
    assigned_user_id: Yup.object()
        .required('page.add_assignee_ids_required')
        .test('is-empty', 'page.add_assignee_ids_required', value => {
            return value && Object.keys(value).length > 0;
        }),
});
export default validationSchema;