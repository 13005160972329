import { useMutation, useQuery } from 'react-query';
import { TaskWorkLogService } from '../api/TaskWorkLogService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListTaskWorkLog = ([project_id,project_task_id,workLogPage,searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['task-work-log-list', project_id,project_task_id,workLogPage,searchText],
    () => TaskWorkLogService.listTaskWorkLog({ project_id, project_task_id, workLogPage, searchText }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Add
const useAddTaskWorkLog = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskWorkLogService.addTaskWorkLog, {
    onSuccess,
    onError
  })
}
// Update
const useUpdateTaskWorkLog = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskWorkLogService.updateTaskWorkLog, {
    onSuccess,
    onError
  })
}
// Remove
const useDeleteTaskWorkLog = () => {
  const deleteTaskWorkLog = async (projectId, projectTaskId, projectTaskWorkLogId) => {
    return await TaskWorkLogService.deleteTaskWorkLog(projectId, projectTaskId, projectTaskWorkLogId);
  };
  return { deleteTaskWorkLog };
};
// Edit
const useEditTaskWorkLog = () => {
  const editTaskWorkLog = async (projectId, projectTaskId, projectTaskWorkLogId) => {
    return await TaskWorkLogService.editTaskWorkLog(projectId, projectTaskId, projectTaskWorkLogId);
  };
  return { editTaskWorkLog };
};
export {
  useListTaskWorkLog,
  useAddTaskWorkLog,
  useUpdateTaskWorkLog,
  useDeleteTaskWorkLog,
  useEditTaskWorkLog
};