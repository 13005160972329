import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
// helper
import { formatTimeSpent } from '../../../../../helpers/commonHelpers';

const getProfileImageURL = (userId, profileImage) => {
  if (profileImage) {
    let imageURL = `${process.env.MIX_AWS_URL}user_profile/${userId}/${profileImage}`;
    return imageURL;
  } else {
    return false;
  }
};
const getImageName = (firstName, lastName) => {
  let imageName = 'N';
  if (firstName) {
    imageName = firstName.substring(0, 1);
  }
  if (lastName) {
    imageName += lastName.substring(0, 1);
  }
  return imageName;
};

const RenderLogContent = ({ log }) => {
  if (log.history_type === 8) {
    if (log.type === 2) {
      return (
        <>
          <span className="badge badge-theme-color">
            {log.before_change && log.before_change !== '' && log.before_change !== 0
              ? formatTimeSpent(log.before_change)
              : 'None'}
          </span>
          <FontAwesomeIcon icon={faArrowRight} />
          <span className="badge badge-theme-color" style={{ marginLeft: '20px' }}>
            {formatTimeSpent(log.after_change)}
          </span>
        </>
      );
    } else {
      return <span className="badge badge-theme-color"></span>;
    }
  } else if (log.history_type === 6) {
    // Task
    if (log.type === 2) {
      return (
        <>
          <span
            className="react_quill_content_data"
            dangerouslySetInnerHTML={{ __html: log.before_change }}></span>
          <FontAwesomeIcon icon={faArrowRight} />
          <span
            className="react_quill_content_data"
            dangerouslySetInnerHTML={{ __html: log.after_change }}></span>
        </>
      );
    } else {
      return <span className="badge badge-theme-color"></span>;
    }
  } else if (log.history_type === 7) {
    // Task Desc
    return (
      <>
        <span
          className="react_quill_content_data"
          dangerouslySetInnerHTML={{ __html: log.before_change }}></span>
        <FontAwesomeIcon icon={faArrowRight} />
        <span
          className="react_quill_content_data"
          dangerouslySetInnerHTML={{ __html: log.after_change }}></span>
      </>
    );
  } else if (log.history_type === 2) {
    return (
      <>
        {log?.before_change_user_profile_image && log?.before_change_user_profile_image != '' ? (
          <>
            <div
              data-toggle="tooltip"
              data-placement="top"
              title={log?.before_change}
              style={{ margin: '0px' }}
              className="profileImageWithImage">
              <img
                src={getProfileImageURL(
                  log?.before_change_user?.user_id,
                  log?.before_change_user_profile_image
                )}
              />
            </div>
            <span>
              {log.before_change && log.before_change !== '' && log.before_change !== 0
                ? log.before_change
                : 'None'}{' '}
            </span>
          </>
        ) : (
          <>
            <div
              data-toggle="tooltip"
              data-placement="top"
              title={log?.before_change}
              style={{ margin: '0px' }}
              className="profileImageWithoutImage">
              {getImageName(
                log?.before_change_user?.first_name,
                log?.before_change_user?.last_name
              )}
            </div>
            <span>
              {log.before_change && log.before_change !== '' && log.before_change !== 0
                ? log.before_change
                : 'None'}{' '}
            </span>
          </>
        )}
        <FontAwesomeIcon icon={faArrowRight} />
        {log?.after_change_user_profile_image && log?.after_change_user_profile_image != '' ? (
          <>
            <div
              data-toggle="tooltip"
              data-placement="top"
              title={log?.after_change}
              style={{ margin: '0px' }}
              className="profileImageWithImage">
              <img
                src={getProfileImageURL(
                  log?.after_change_user?.user_id,
                  log?.after_change_user_profile_image
                )}
              />
            </div>
            <span>{log?.after_change ?? 'None'} </span>
          </>
        ) : (
          <>
            <div
              data-toggle="tooltip"
              data-placement="top"
              title={log?.after_change}
              style={{ margin: '0px' }}
              className="profileImageWithoutImage">
              {getImageName(log?.after_change_user?.first_name, log?.after_change_user?.last_name)}
            </div>
            <span>{log?.after_change ?? 'None'} </span>
          </>
        )}
      </>
    );
  } else if (log.history_type === 4) {
    return (
      <>
        <span className="badge badge-theme-color">
          {log.before_change && log.before_change !== '' && log.before_change !== 0
            ? log.before_change + ' H'
            : 'None'}
        </span>
        <FontAwesomeIcon icon={faArrowRight} />
        <span className="badge badge-theme-color" style={{ marginLeft: '20px' }}>
          {log.after_change + ' H'}
        </span>
      </>
    );
  } else {
    return (
      <>
        <span className="badge badge-theme-color">
          {log.before_change && log.before_change !== '' && log.before_change !== 0
            ? log.before_change
            : 'None'}
        </span>
        <FontAwesomeIcon icon={faArrowRight} />
        <span className="badge badge-theme-color" style={{ marginLeft: '20px' }}>
          {log.after_change}
        </span>
      </>
    );
  }
};

export default RenderLogContent;
