import client from '@libs/HttpClient';
class MasterService {
  // Translation Master
  static addTranslation(request) {
    return client.post('/translations', request);
  }
  static updateTranslation(request) {
    return client.put(`/translations/${request.translation_id}`, request);
  }
  static listTranslation(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    return client.get(`/translations/?page=${page_no}&search_text=${searchText}`, request);
  }
  static viewTranslation(request) {
    const translation = request.queryKey[1];
    return client.get(`/translations/${translation}`, request);
  }
  static syncTranslation(request) {
    return client.get(`/translations/sync-data`, request);
  }
  // Email Template Master
  static listEmailTemplate(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    return client.get(`/email-template/?page=${page_no}&search_text=${searchText}`, request);
  }
  static addEmailTemplate(request) {
    console.log("request--->",request)
    return client.post(`/email-template`, request);
  }
  static viewEmailTemplate(request) {
    const email_template = request.queryKey[1];
    return client.get(`/email-template/${email_template}/edit`, request);
  }
  static updateEmailTemplate(request) {
    return client.put(`/email-template/${request.email_template_id}`, request);
  }
  // FAQ Master
  static listFaq(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const category_id = request.queryKey[4] || '';
    return client.get(
      `/faq/?search=${searchText}&page=${page_no}&status=${status}&faq_category_id=${category_id}`,
      request
    );
  }
  static addFaq(request) {
    return client.post(`/faq`, request);
  }
  static viewFaq(params) {
    return client.get(`/faq/${params}/edit`);
  }
  static updateFaq(request) {
    return client.put(`/faq/${request.id}`, request);
  }
  static deleteFaq(request) {
    return client.delete(`/faq/${request.id}`, request);
  }
  // FAQ Category Master
  static getFaqCategory(request) {
    return client.get(`/faq/faq-category-list`, request);
  }
  static getFaqCategoryList(request) {
    return client.get(`/faq/faq-category-list-filter`, request);
  }
  static statusChangeFaq(request) {
    return client.patch(`/faq/update-status/${request.id}`, request);
  }
  static listFaqCategory(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    return client.get(
      `/faq-category/?search=${searchText}&page=${page_no}&status=${status}`,
      request
    );
  }
  static addFaqCategory(request) {
    return client.post(`/faq-category`, request);
  }
  static viewFaqCategory(params) {
    return client.get(`/faq-category/${params}/edit`);
  }
  static updateFaqCategory(request) {
    return client.put(`/faq-category/${request.id}`, request);
  }
  static deleteFaqCategory(request) {
    return client.delete(`/faq-category/${request.id}`, request);
  }
  static statusChangeFaqCategory(request) {
    return client.patch(`/faq-category/update-status/${request.id}`, request);
  }
}
export { MasterService };