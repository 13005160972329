// React Dependencies
import { useState, React, useRef } from 'react';
// Common Dependencies
import { useParams, useNavigate } from 'react-router-dom';
// Component
import { Button, Form, Card, Row, Col } from 'react-bootstrap';

import { TNButton } from '@common/components';
import validationSchema from './AddEditWorkLogValidation';

import PropTypes from 'prop-types';
// Modal Component
import Modal from 'react-bootstrap/Modal';
// API
import { useAddTaskWorkLog } from '@hooks';
// Validation
import { useFormik } from 'formik';
// Helper
import { defaultValue } from '@helpers';
// Alert
import { toast } from 'react-toastify';
import CustomReactQuillEditor from '../../../../../common/components/CustomReactQuillEditor';
// // CK Editor
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const moment = require('moment');

const AddWorkLogPopup = ({
  t,
  socket,
  refetch,
  handleClose,
  show,
  setWorkLogPage,
  workLogPage,
  projectTeamMembers
}) => {
  // Project ID & Project Task Id
  let { id, projectTaskId } = useParams();
  const [descriptionD, setDescriptionD] = useState('');

  const reactQuillRef = useRef(null);

  // Add Task Log API
  const { mutate: doAddWorkLog, isLoading } = useAddTaskWorkLog(
    (response) => {
      toast.success(response.message);
      formik.resetForm();
      handleClose();
      if (workLogPage == 1) {
        refetch();
      } else {
        setWorkLogPage(1);
      }
      socket.emit('worklog-updated');
    },
    (error) => {
      if (Array.isArray(error.message)) {
        error.message.map((er) => {
          toast.error(er);
        });
      } else {
        toast.error(error.message);
      }
    }
  );
  // Validation And Submit
  const formik = useFormik({
    initialValues: {
      project_id: id,
      project_task_id: projectTaskId,
      time_spent_in_hour: '',
      time_spent_in_minute: '',
      work_log_date: moment().format('YYYY-MM-DD'),
      description: ''
    },
    validationSchema,
    onSubmit: (values) => {
      doAddWorkLog(values);
    }
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>{t('page.time_tracking')}</Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12} xs={12}>
                <label className="field-label form-label" style={{ marginBottom: '0px' }}>
                  {t('page.time_spent')}
                </label>
                <Row>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {/* <Form.Label className="field-label">
                        {t('page.time_spent_hour')}
                      </Form.Label> */}
                      <Form.Control
                        className={
                          ' ' +
                          (formik.touched.time_spent_in_hour && formik.errors.time_spent_in_hour
                            ? 'form-field-error'
                            : formik.touched.time_spent_in_hour && !formik.errors.time_spent_in_hour
                              ? 'form-field-success'
                              : '')
                        }
                        type="text"
                        name="time_spent_in_hour"
                        placeholder={t('page.task_time_spent_hour_placeholder')}
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.time_spent_in_hour}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (event.target.value === '' || re.test(event.target.value)) {
                            formik.setFieldValue('time_spent_in_hour', Number(event.target.value));
                          }
                        }}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.time_spent_in_hour && formik.errors.time_spent_in_hour ? (
                          <div>{t(formik.errors.time_spent_in_hour)}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      {/* <Form.Label className="field-label">
                        {t('page.time_spent_minute')}
                      </Form.Label> */}
                      <Form.Control
                        className={
                          ' ' +
                          (formik.touched.time_spent_in_minute && formik.errors.time_spent_in_minute
                            ? 'form-field-error'
                            : formik.touched.time_spent_in_minute &&
                                !formik.errors.time_spent_in_minute
                              ? 'form-field-success'
                              : '')
                        }
                        type="text"
                        name="time_spent_in_minute"
                        placeholder={t('page.task_time_spent_minute_placeholder')}
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.time_spent_in_minute}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (event.target.value === '' || re.test(event.target.value)) {
                            formik.setFieldValue(
                              'time_spent_in_minute',
                              Number(event.target.value)
                            );
                          }
                        }}
                      />
                      <div className="form-field-error-text">
                        {formik.touched.time_spent_in_minute &&
                        formik.errors.time_spent_in_minute ? (
                          <div>{t(formik.errors.time_spent_in_minute)}</div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.work_log_date_label')}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="work_log_date"
                    placeholder={t('page.work_log_date_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.work_log_date}
                    onClick={(event) => {
                      event.target.showPicker();
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.work_log_date && formik.errors.work_log_date ? (
                      <div>{t(formik.errors.work_log_date)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={12}>
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.description')}
                  </Form.Label>
                  {/* <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                  /> */}
                  <CustomReactQuillEditor
                    t={t}
                    ref={reactQuillRef}
                    value={descriptionD}
                    taggingUsers={projectTeamMembers}
                    onChange={(content) => {
                      setDescriptionD(content);
                      formik.setFieldValue('description', content);
                    }}
                    placeholder={t('page.react-quill-placeholder')}
                    onFocus={() => console.log('Editor focused')}
                    onBlur={() => console.log('Editor blurred')}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.description && formik.errors.description ? (
                      <div>{t(formik.errors.description)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <div className="m-2" style={{ float: 'right' }}>
              <Button variant="danger" onClick={handleClose} style={{ marginRight: '5px' }}>
                {t('page.time_tracking_popup_cancle_btn')}
              </Button>
              <Button
                variant="primary"
                type="submit"
                loading={isLoading}
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                {t('page.time_tracking_popup_save_btn')}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
AddWorkLogPopup.propTypes = {
  t: PropTypes.func
};
export default AddWorkLogPopup;
