import client from '@libs/HttpClient';

class OptionService {
  static projectTasksOptions = async (request) => {
    const projectId = request[0];
    const response = await client.get(`/get-options/project/tasks/${projectId}`, request);
    return response.data;
  };
}
export { OptionService };
