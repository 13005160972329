// React Dependencies
import { useState, useMemo, React, useEffect } from 'react';
// Common Dependencies
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { TNTable, TNButton } from '@common/components';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
import makeAnimated from 'react-select/animated';
// API
import {
  useRouteAccessList,
  useListTask,
  useDeleteTask,
  useGetTaskPriorities,
  useGetSprintList,
  useGetAssignedProjectEmployeeList,
  useGetTaskStatusTypesWithSubTasks,
  useUpdateTaskStatus
} from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
// Asset
import '@assets/scss/components/_task.scss';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
import { formatTimeSpent } from '../../../../helpers/commonHelpers';
const ListTaskPage = ({ t, socket }) => {
  // Socket Initialization
  useEffect(() => {
    socket.on('get-latest', (data) => {
      setNewBoardData(data);
    });
  }, []);
  // Navigate
  const navigate = useNavigate();
  // Project ID
  let { id } = useParams();
  // Get the value from parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const viewTypeParameter = queryParams.get('viewType'); // Get the value of viewType
  // States
  const [boardData, setBoardData] = useState([]);
  const [viewType, setViewType] = useState(
    viewTypeParameter != 'null' && viewTypeParameter ? viewTypeParameter : 2
  );
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminTask !== undefined && localStorage.adminTask !== ''
      ? JSON.parse(localStorage.adminTask).currentPage
      : 1
  );
  const [paginationData, setPaginationData] = useState(null);
  const [searchText, setSearchText] = useState(
    localStorage.adminTask !== undefined && localStorage.adminTask !== ''
      ? JSON.parse(localStorage.adminTask).searchText
      : ''
  );
  const [data, setData] = useState([]);
  const [routeName, setRouteNames] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(
    localStorage.adminTask !== undefined && localStorage.adminTask !== ''
      ? JSON.parse(localStorage.adminTask).selectedPriority
      : ''
  );
  const [selectedAssignee, setSelectedAssignee] = useState(
    localStorage.adminTask !== undefined && localStorage.adminTask !== ''
      ? JSON.parse(localStorage.adminTask).selectedAssignee
      : ''
  );
  const [selectedSprint, setSelectedSprint] = useState(
    localStorage.adminTask !== undefined && localStorage.adminTask !== ''
      ? JSON.parse(localStorage.adminTask).selectedSprint
      : ''
  );
  const [taskFilter, setTaskFilter] = useState({
    boardData: boardData,
    selectedPriority: selectedPriority,
    selectedAssignee: selectedAssignee,
    selectedSprint: selectedSprint,
    searchText: searchText
  });
  // Route
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  const addTask = () => {
    navigate(`/projects/view/${id}/task/add?viewType=${viewType}`);
  };
  // Filter Handlers
  const handleClearFilter = () => {
    setSearchText('');
    setSelectedPriority('');
    setSelectedAssignee('');
    setSelectedSprint('');
    setCurrentPage(1);
    localStorage.removeItem('adminTask');
    setTaskFilter({
      ...taskFilter,
      selectedPriority: '',
      selectedSprint: '',
      selectedAssignee: '',
      searchText: ''
    });
  };
  const handleSearch = (search_text) => {
    setSearchText(search_text.target.value);
    setTaskFilter({
      ...taskFilter,
      searchText: search_text.target.value
    });
  };
  // On Load Set Filters And Fetch Records
  useEffect(() => {
    let adminTask = {
      id: id,
      currentPage: currentPage,
      searchText: searchText,
      selectedPriority: selectedPriority,
      selectedAssignee: selectedAssignee,
      selectedSprint: selectedSprint
    };
    localStorage.adminTask = JSON.stringify(adminTask);
  }, [id, currentPage, searchText, selectedPriority, selectedAssignee, selectedSprint]);
  // Fetch Records
  const { refetch, isLoading } = useListTask(
    [id, currentPage, searchText, selectedPriority, selectedAssignee, selectedSprint],
    (res) => {
      setData(res.data.project_task_list);
      setPaginationData(res.data.pagination);
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  // Filters Initialization
  // Task Priorities List
  const { data: taskPriorityList, isLoading: isTaskPriorityLoading } = useGetTaskPriorities();
  let options2 = [];
  if (taskPriorityList !== undefined && !isTaskPriorityLoading) {
    Object.entries(taskPriorityList).map(([value, label]) => {
      options2.push({ value, label });
      return true;
    });
  }
  // Sprint List
  const { data: optionList, isLoading: isSprintLoading } = useGetSprintList(id);
  let options = [];
  if (optionList !== undefined && !isSprintLoading) {
    optionList.data.map((val) => {
      options.push({
        value: val.project_sprint_id,
        label: val.name
      });
      return true;
    });
  }
  // Get Employee List
  const { data: employeeList, isLoading: isUserLoading } = useGetAssignedProjectEmployeeList(id);
  const options1 = [];
  if (employeeList !== undefined && !isUserLoading) {
    employeeList.data.employee_list.map((val) => {
      options1.push({
        value: val.user_id,
        label: val.first_name + ' ' + val.last_name
      });
      return true;
    });
  }
  // Task Status Type List
  const { data: taskStatusTypeList, isLoading: isTaskStatusTypeLoading } =
    useGetTaskStatusTypesWithSubTasks(id);
  useEffect(() => {
    if (taskStatusTypeList !== undefined && !isTaskStatusTypeLoading) {
      setTaskFilter({
        ...taskFilter,
        boardData: taskStatusTypeList?.data
      });
    }
  }, [taskStatusTypeList]);
  useEffect(() => {
    setBoardDataFunc(taskFilter);
  }, [taskFilter]);
  // Board View Profile Image
  const getProfileImageURL = (userId, profileImage) => {
    if (profileImage) {
      let imageURL = `${process.env.MIX_AWS_URL}user_profile/${userId}/${profileImage}`;
      return imageURL;
    } else {
      return false;
    }
  };
  const getImageName = (firstName, lastName) => {
    let imageName = 'US';
    if (firstName) {
      imageName = firstName.substring(0, 1);
    }
    if (lastName) {
      imageName += lastName.substring(0, 1);
    }
    return imageName;
  };
  const setNewBoardData = (newBoardData) => {
    setTaskFilter({
      ...taskFilter,
      boardData: newBoardData,
      selectedPriority:
        localStorage.adminTask !== undefined && localStorage.adminTask !== ''
          ? JSON.parse(localStorage.adminTask).selectedPriority
          : '',
      selectedAssignee:
        localStorage.adminTask !== undefined && localStorage.adminTask !== ''
          ? JSON.parse(localStorage.adminTask).selectedAssignee
          : '',
      selectedSprint:
        localStorage.adminTask !== undefined && localStorage.adminTask !== ''
          ? JSON.parse(localStorage.adminTask).selectedSprint
          : '',
      searchText:
        localStorage.adminTask !== undefined && localStorage.adminTask !== ''
          ? JSON.parse(localStorage.adminTask).searchText
          : ''
    });
  };
  const setBoardDataFunc = (taskFilter) => {
    if (taskFilter) {
      let columns = [];
      taskFilter?.boardData?.forEach((object) => {
        let columnObject = object;
        let total_tasks = object?.tasks?.length ?? 0;
        let total_shown = 0;
        let cardsArray = [];
        object?.tasks.forEach((subObject) => {
          let isValidShow = 1;
          let isValidPriority = taskFilter?.selectedPriority ? 0 : 1;
          let isValidSprint = taskFilter?.selectedSprint ? 0 : 1;
          let isValidAssignee = taskFilter?.selectedAssignee ? 0 : 1;
          let isValidSearch = taskFilter?.searchText ? 0 : 1;
          // Compare Priorities
          let selectedPriorityArray = taskFilter?.selectedPriority
            ? taskFilter?.selectedPriority.split(',')
            : [];
          let priority = subObject?.priority ? subObject?.priority.toString() : '';
          if (
            selectedPriorityArray &&
            selectedPriorityArray?.length > 0 &&
            priority &&
            selectedPriorityArray.includes(priority)
          ) {
            isValidPriority = 1;
          }
          // Compare Sprints
          let selectedSprintArray = taskFilter?.selectedSprint
            ? taskFilter?.selectedSprint.split(',')
            : [];
          let sprint = subObject?.project_sprint_id ? subObject?.project_sprint_id.toString() : '';
          if (
            selectedSprintArray &&
            selectedSprintArray?.length > 0 &&
            sprint &&
            selectedSprintArray.includes(sprint)
          ) {
            isValidSprint = 1;
          }
          // Compare Assignee
          let selectedAssigneeArray = taskFilter?.selectedAssignee
            ? taskFilter?.selectedAssignee.split(',')
            : [];
          // if(Array.isArray(subObject?.users)){
          //   if (selectedAssigneeArray && selectedAssigneeArray?.length > 0 && subObject?.users) {
          //       let filterUsers = subObject?.users.filter((row) => {
          //         console.log('row:', row)
          //         console.log('selectedAssigneeArray:', selectedAssigneeArray)
          //         console.log('row?.user_id:', row?.user_id)
          //          return selectedAssigneeArray.includes(row?.user_id.toString())
          //         });
          //       if (filterUsers && filterUsers?.length > 0) {
          //         isValidAssignee = 1;
          //       }
          //     }

          //   // Compare Search Text
          //   let searchTextValue = taskFilter?.searchText ? taskFilter?.searchText : "";
          //   if (searchTextValue && searchTextValue != "") {
          //     let searchTaskName = subObject?.name.toLowerCase().search(searchTextValue);
          //     let filterUsers = subObject?.users.filter((row) => {
          //       let stringMatch = row?.full_name.toLowerCase().search(searchTextValue);
          //       return stringMatch >= 0;
          //     });
          //     let isValidTaskSearch = searchTaskName >= 0 ? 1 : 0;
          //     let isValidUserSearch = filterUsers?.length > 0 ? 1 : 0;
          //     let totalIsValidSearch = isValidTaskSearch + isValidUserSearch;
          //     if(totalIsValidSearch > 0){
          //       isValidSearch = 1;
          //     }
          //   }
          // }else{
          if (selectedAssigneeArray && selectedAssigneeArray.length > 0 && subObject?.users) {
            const userId = subObject?.users?.user_id.toString(); // Get user_id from the single record
            if (selectedAssigneeArray.includes(userId)) {
              isValidAssignee = 1; // Set isValidAssignee if there's a match
            }
          }

          // Compare Search Text
          let searchTextValue = taskFilter?.searchText ? taskFilter.searchText : '';
          if (searchTextValue) {
            let searchTaskName = subObject?.name.toLowerCase().indexOf(searchTextValue);
            // Check if subObject.users is a single user record
            let isValidUserSearch = 0;
            if (subObject?.users) {
              let userFullName = subObject.users.full_name.toLowerCase();
              isValidUserSearch = userFullName.includes(searchTextValue.toLowerCase()) ? 1 : 0;
            }

            let isValidTaskSearch = searchTaskName >= 0 ? 1 : 0;
            let totalIsValidSearch = isValidTaskSearch + isValidUserSearch;

            if (totalIsValidSearch > 0) {
              isValidSearch = 1;
            }
          }
          // }

          // Check Valid Filter
          let totalValid = isValidPriority + isValidSprint + isValidAssignee + isValidSearch;
          isValidShow = totalValid == 4 ? 1 : 0;
          // Array Creation
          let cardObject = subObject;
          cardObject['is_show'] = isValidShow;
          // Users Image
          // let usersArray = [];
          // subObject?.users.forEach(deepSubObject => {
          let userObject = subObject?.users;
          if (userObject && typeof userObject === 'object') {
            let getImageURL = getProfileImageURL(userObject.user_id, userObject.profile_image);
            if (getImageURL) {
              userObject['image_url'] = getImageURL;
            } else {
              userObject['image_url'] = '';
            }
            userObject['image_name'] = getImageName(userObject.first_name, userObject.last_name);
          }
          // usersArray.push(userObject);
          // });
          cardObject['users'] = userObject;
          cardsArray.push(cardObject);
          total_shown = total_shown + isValidShow;
        });
        columnObject['total_tasks'] = total_tasks;
        columnObject['total_shown'] = total_shown;
        columns.push(columnObject);
      });
      // let boardValuesData = { columns: columns };
      setBoardData(columns);
    }
  };
  // Update Task Status
  // Add Task API
  const { mutate: updateTaskStatus } = useUpdateTaskStatus(
    (response) => {
      socket.emit('task-updated', response?.data);
      toast.success(response.message);
    },
    (error) => {
      toast.error(error.message);
    }
  );
  // Remove Task Handler
  const { deleteTask } = useDeleteTask();
  const handleDeleteClick = (id, tid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_task_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTask(id, tid);
                    if (response && response.message) {
                      toast.success(response.message);
                      socket.emit('task-updated', response?.data);
                    }
                    onClose();
                    refetch();
                  } catch (error) {
                    toast.error(error.message);
                    onClose();
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };
  // Action Button Handlers
  const handleEditClick = (id, taskId, viewTypeP = 2) => {
    navigate(`/projects/view/${id}/task/${taskId}/edit?viewType=${viewTypeP}`);
  };
  const handleViewClick = (id, taskId, viewTypeP = 2) => {
    navigate(`/projects/view/${id}/task/${taskId}/details?viewType=${viewTypeP}`);
  };
  // Table Columns
  const actionColumn = {
    Header: `${t('page.action_column')}`,
    accessor: 'project_task_id',
    Cell: ({ value: taskId }) => {
      return (
        <div className="d-inline-flex">
          {shouldShowRoute('projects.task.view') && (
            <TNButton
              className="table-primary-button"
              onClick={() => handleViewClick(id, taskId, 1)}>
              {t('page.action_button_text_view')}
            </TNButton>
          )}
          {shouldShowRoute('projects.task.edit') && (
            <TNButton
              className="table-primary-button"
              onClick={() => handleEditClick(id, taskId, 1)}>
              {t('page.action_button_text_edit')}
            </TNButton>
          )}
          {shouldShowRoute('projects.task.destroy') && (
            <TNButton
              className="table-delete-button"
              onClick={() => handleDeleteClick(id, taskId, 1)}>
              {t('page.action_button_text_delete')}
            </TNButton>
          )}
        </div>
      );
    },
    disableSortBy: true
  };
  let showActionColumn = shouldShowRoute('projects.team.destroy');
  const columnsjson = [
    {
      Header: `${t('page.task_created_at')}`,
      accessor: 'created_at',
      disableSortBy: true
    },
    {
      Header: `${t('page.project_task_name')}`,
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <p style={{ width: '250px' }}>{row?.original?.name}</p>;
      }
    },
    {
      Header: `${t('page.task_estimate_time')}`,
      accessor: 'original_estimate_time',
      disableSortBy: true
    },
    {
      Header: `${t('page.task_assignee')}`,
      accessor: 'assignee_name',
      disableSortBy: true
    },
    {
      Header: `${t('page.task_Sprint_name')}`,
      accessor: 'sprint_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <p>
              {row?.original?.sprint_name} |{' '}
              <span className="text-success">{row?.original?.task_status}</span>
            </p>
          </>
        );
      }
    }
    // {
    //   Header: `${t('page.task_status')}`,
    //   accessor: 'task_status',
    //   disableSortBy: true,
    // },
  ];
  if (showActionColumn) {
    columnsjson.push(actionColumn);
  }
  const columns = useMemo(() => columnsjson, [routeName]);
  if (paginationData === null) {
    return null;
  }
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };
  if (isLoading) {
    return null;
  }
  const animatedComponents = makeAnimated();
  // Task View Type
  const taskViewType = (type) => {
    setViewType(type);
  };
  const onDragStart = (evt) => {
    let element = evt.currentTarget;
    element.classList.add('dragged');
    evt.dataTransfer.setData('text/plain', evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = 'move';
  };
  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove('dragged');
  };
  const onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add('dragged-over');
    evt.dataTransfer.dropEffect = 'move';
  };
  const onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget) return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove('dragged-over');
  };
  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };
  const onDrop = (evt, task_status_type_id) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove('dragged-over');
    let data = evt.dataTransfer.getData('text/plain');
    const requestObject = {
      project_id: id,
      task_id: data,
      task_status_type_id: task_status_type_id
    };
    updateTaskStatus(requestObject);
  };
  return (
    <>
      <CommonSEOTitle title={t('page.project_task_tab')} />
      <ProjectNavigation t={t} id={id} activeLabel="Tasks" />
      <Row>
        <Col lg={12}>
          <div style={{ float: 'left', marginTop: '20px' }}>
            <Button
              type="button"
              onClick={() => {
                taskViewType(1);
              }}
              disabled={viewType == 1 ? true : false}
              className="btn btn-primary listViewButtons">
              {t('page.task_list_view')}
            </Button>
            <Button
              type="button"
              onClick={() => {
                taskViewType(2);
              }}
              disabled={viewType == 2 ? true : false}
              className="btn btn-primary listViewButtons">
              {t('page.task_board_view')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          {/* <div className="filter" style={{ float: 'right' }}> */}
          <div className="filter">
            <Form.Group className="filter-field-space">
              <Select
                className="filter-column"
                options={options2}
                onChange={(selectedOption) => {
                  let prioritiesArray = [];
                  selectedOption.forEach((object) => {
                    prioritiesArray.push(object?.value);
                  });
                  prioritiesArray = prioritiesArray.join(',');
                  setCurrentPage(1);
                  setSelectedPriority(prioritiesArray);
                  setTaskFilter({
                    ...taskFilter,
                    selectedPriority: prioritiesArray
                  });
                }}
                components={animatedComponents}
                isMulti
                value={
                  selectedPriority
                    ? options2.filter((row) => {
                        return selectedPriority.split(',').includes(row?.value?.toString());
                      })
                    : ''
                }
              />
            </Form.Group>
            <Form.Group className="filter-field-space">
              <Select
                className="filter-column task-filters"
                options={options}
                onChange={(selectedOption) => {
                  let sprintArray = [];
                  selectedOption.forEach((object) => {
                    sprintArray.push(object?.value);
                  });
                  sprintArray = sprintArray.join(',');
                  setCurrentPage(1);
                  setSelectedSprint(sprintArray);
                  setTaskFilter({
                    ...taskFilter,
                    selectedSprint: sprintArray
                  });
                }}
                components={animatedComponents}
                isMulti
                value={
                  selectedSprint
                    ? options.filter((row) => {
                        return selectedSprint.split(',').includes(row?.value?.toString());
                      })
                    : ''
                }
              />
            </Form.Group>
            <Form.Group className="filter-field-space">
              <Select
                className="filter-column task-filters"
                options={options1}
                onChange={(selectedOption) => {
                  let assigneeArray = [];
                  selectedOption.forEach((object) => {
                    assigneeArray.push(object?.value);
                  });
                  assigneeArray = assigneeArray.join(',');
                  setCurrentPage(1);
                  setSelectedAssignee(assigneeArray);
                  setTaskFilter({
                    ...taskFilter,
                    selectedAssignee: assigneeArray
                  });
                }}
                components={animatedComponents}
                isMulti
                value={
                  selectedAssignee
                    ? options1.filter((row) => {
                        return selectedAssignee.split(',').includes(row?.value?.toString());
                      })
                    : ''
                }
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder="Search"
                onKeyUp={handleSearch}
                onChange={handleSearch}
                className="filter-column form-field task-search"
                value={searchText}
              />
            </Form.Group>
            <Button
              type="button"
              onClick={handleClearFilter}
              className="secondary-remove-button"
              disabled={
                searchText || selectedPriority || selectedAssignee || selectedSprint ? false : true
              }>
              {t('page.cancel_search_button')}
            </Button>
            <TNButton
              style={{ float: 'right', clear: 'both' }}
              className="table-add-button"
              loading={false}
              type="button"
              onClick={addTask}>
              {t('page.task_add_button')}
            </TNButton>
          </div>
        </Col>
      </Row>
      {viewType == 1 && (
        <TNTable
          columns={columns}
          data={data}
          paginationData={paginationData}
          onSelectPage={pageIndexHandle}
          t={t}
          pageIndexGet={currentPage - 1}
          key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        />
      )}
      {viewType == 2 && (
        <>
          {boardData ? (
            <div className="draggableMainDiv">
              {boardData.map((value, index) => {
                return (
                  <Col className="draggableParentDiv" lg={2} key={`col_${index}`}>
                    <div
                      key={`div_draggable_${index}`}
                      className="small-box"
                      onDragLeave={(e) => onDragLeave(e)}
                      onDragEnter={(e) => onDragEnter(e)}
                      onDragEnd={(e) => onDragEnd(e)}
                      onDragOver={(e) => onDragOver(e)}
                      onDrop={(e) => onDrop(e, value.task_status_type_id)}>
                      <section className="drag_container">
                        <div className="p-1">
                          <div className="drag_column">
                            <div className="drag_row">
                              <Row>
                                <Col lg={12}>
                                  <h3>
                                    {value.status_name} &nbsp; {value.total_shown ?? 0}
                                    {'/'}
                                    {value.total_tasks ?? 0}
                                  </h3>
                                </Col>
                              </Row>
                              <br />
                              {value?.tasks.map((task) => (
                                <div
                                  style={{ display: task?.is_show == 1 ? 'block' : 'none' }}
                                  className="card draggableCard"
                                  key={task.name}
                                  id={task.project_task_id}
                                  draggable
                                  onDragStart={(e) => onDragStart(e)}
                                  onDragEnd={(e) => onDragEnd(e)}>
                                  <Row>
                                    <Col
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={task.name}
                                      lg={12}
                                      className="taskTitle">
                                      {shouldShowRoute('projects.task.view') ? (
                                        <h3
                                          onClick={() => handleViewClick(id, task.project_task_id)}>
                                          {task.name}
                                        </h3>
                                      ) : (
                                        <h3>{task.name}</h3>
                                      )}
                                    </Col>
                                    <Col
                                      className="timeSpentDiv"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={
                                        'Time Spent : ' + formatTimeSpent(task.total_time_taken)
                                      }
                                      lg={12}>
                                      <h5>{formatTimeSpent(task.total_time_taken)}</h5>
                                    </Col>
                                    <Col lg={10} className="totalSpentTime">
                                      <span className="badge badge-pill badge-secondary">
                                        {task.original_estimate_time}
                                        {' H'}
                                      </span>
                                      {task.priority == 1 && (
                                        <span className="badge badge-pill badge-secondary">
                                          Low
                                        </span>
                                      )}
                                      {task.priority == 2 && (
                                        <span className="badge badge-pill badge-warning">
                                          Medium
                                        </span>
                                      )}
                                      {task.priority == 3 && (
                                        <span className="badge badge-pill badge-danger">High</span>
                                      )}
                                    </Col>
                                    <Col className="assigneeDiv" lg={2}>
                                      {
                                        // task?.users.map((userObject, index) => (
                                        <div key={index}>
                                          {task?.users?.image_url ? (
                                            <div
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title={task?.users?.full_name}
                                              key={index}
                                              className="profileImageWithImage">
                                              <img
                                                src={task?.users?.image_url}
                                                alt={task?.users?.full_name}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title={task?.users?.full_name}
                                              key={index}
                                              className="profileImageWithoutImage">
                                              {task?.users?.image_name}
                                            </div>
                                          )}
                                        </div>
                                        // ))
                                      }
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </Col>
                );
              })}
            </div>
          ) : (
            'NO Boards Available'
          )}
        </>
      )}
    </>
  );
};
ListTaskPage.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func,
  row: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.any
};
export default ListTaskPage;
