import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const UserList = React.lazy(() => import('./UserList'));
const AddUserPage = React.lazy(() => import('./AddUserPage'));
const EditUserPage = React.lazy(() => import('./EditUserPage'));
const ViewUser = React.lazy(() => import('./ViewUser'));
import UserAddressRoutes from './Address/UserAddressRoutes';
import UserContactRoutes from './Contact/UserContactRoutes';
import UserDepartmentAndDesignationRoutes from './DepartmentAndDesignation/UserDepartmentAndDesignationRoutes';
import UserApproverRoutes from './Approver/UserApproverRoutes';

const UserRoutes = ({ t }) => {
  return (
    <Routes key={'routes'}>
      <Route key={'user_list'} index exact path="/list" element={<UserList t={t} />} />
      <Route key={'user_add'} exact path="/add" element={<AddUserPage t={t} />} />
      <Route exact path="/edit/:id" element={<EditUserPage t={t} />} />
      <Route exact path="/view/:id" element={<ViewUser t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
      {UserAddressRoutes({ t })}
      {UserContactRoutes({ t })}
      {UserDepartmentAndDesignationRoutes({ t })}
      {UserApproverRoutes({ t })}
    </Routes>
  );
};
UserRoutes.propTypes = {
  t: PropTypes.func
};
export default UserRoutes;
