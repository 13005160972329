import { useMutation, useQuery } from 'react-query';
import { AttendanceService } from '@api/AttendanceService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListAttendance = (
  [
    page_no,
    searchText,
    selectedStatus,
    selectedEmployee,
    selectedApprover,
    selectedType,
    selectedDateRange,
    id
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'attendance-list',
      page_no,
      searchText,
      selectedStatus,
      selectedEmployee,
      selectedApprover,
      selectedType,
      selectedDateRange,
      id
    ],
    AttendanceService.listAttendance,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Create
const useAddAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.addAttendance, {
    onSuccess,
    onError
  });
};
// User List
const useGetUserList = (onSuccess, onError = onDefaultError) => {
  return useQuery('attendance-user-list', AttendanceService.getAttendanceUsers, {
    onSuccess,
    onError
  });
};
// Attendance Type List
const useGetAttendanceTypeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('attendance/type-list', AttendanceService.useGetAttendanceTypeList, {
    onSuccess,
    onError
  });
};
// Details
const useViewAttendance = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('attendance-view', () => AttendanceService.viewAttendance(id), {
    onSuccess,
    onError
  });
};
// Update
const useUpdateAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.updateAttendance, {
    onSuccess,
    onError
  });
};
// Remove
const useDeleteAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.deleteAttendance, {
    onSuccess,
    onError
  });
};
// Approve
const useApproveOrRejectAttendance = (onSuccess, onError = onDefaultError) => {
  return useMutation(AttendanceService.approveOrRejectAttendance, {
    onSuccess,
    onError
  });
};

// Get Approver
const useGetApproverList = ([get_all], onSuccess, onError = onDefaultError) => {
  return useQuery(['approver-list', get_all], AttendanceService.approverList, {
    onSuccess,
    onError
  });
};

const useGetLeaveEmployeeList = ([get_all], onSuccess, onError = onDefaultError) => {
  return useQuery(['employee-list', get_all], AttendanceService.leaveEmployeeList, {
    onSuccess,
    onError
  });
};

export {
  useListAttendance,
  useAddAttendance,
  useGetUserList,
  useGetAttendanceTypeList,
  useViewAttendance,
  useUpdateAttendance,
  useDeleteAttendance,
  useApproveOrRejectAttendance,
  useGetApproverList,
  useGetLeaveEmployeeList
};
