import client from '@libs/HttpClient';
class TaskCommentService {
  static listTaskComment(request) {
    const projectId = request.project_id;
    const projectTaskId = request.project_task_id;
    const commentPage = request.commentPage;
    return client.get(`/projects/view/${projectId}/task/${projectTaskId}/comments?page=${commentPage}`,request);
  }
  static addTaskComment(request) {
    const projectId = request.project_id;
    const projectTaskId = request.project_task_id;
    return client.post(`/projects/view/${projectId}/task/${projectTaskId}/comment`, request);
  }
  static updateTaskComment(request) {
    const projectId = request.project_id;
    const projectTaskId = request.project_task_id;
    const projectTaskCommentId = request.project_task_comments_id;
    return client.put(`/projects/view/${projectId}/task/${projectTaskId}/comment/${projectTaskCommentId}`, request);
  }
  static deleteTaskComment(projectId, projectTaskId, projectTaskCommentId) {
    return client.delete(`/projects/view/${projectId}/task/${projectTaskId}/comment/${projectTaskCommentId}`);
  }
}
export { TaskCommentService };