// React Dependencies
import { React, useEffect } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// Components
import { Container, Form, Button } from 'react-bootstrap';
// Validation
import { useFormik } from 'formik';
import validationSchema from './FogetPasswordValidation';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// API Call
import { useForgotPassword } from '@hooks';
// Page Components
import { PublicLayout, LogoUrl } from '@adminlayout';
import { TNButton } from '@common/components';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
const ForgetPasswordPage = (props) => {
  // Props
  const { t } = props;
  // Navigate
  const navigate = useNavigate();
  // On Load Add Class To The Body
  useEffect(() => {
    document.body.classList.add('bg-box');
  }, []);
  // Cancel Form
  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon className="alert-close" icon={faClose} onClick={() => {onClose();}}
              />
              <div className="alert-popup">
                <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
                <Button className="table-delete-button" onClick={() => { onClose(); navigate(`/login/`); }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/login/`);
    }
  };
  // Validate And Submit Request
  const { mutate: doForgotPassword, isLoading } = useForgotPassword(
    (response) => {
      toast.success(response.message);
      localStorage.forgot_token = response.data.user_id;
      localStorage.isAdmin = true;
      navigate('/otp-verification');
    },
    (error) => {
      window.scrollTo(0, 0);
      toast.error(error.message);
    }
  );
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: (values) => {
      doForgotPassword(values);
    }
  });
  return (
    <>
      <CommonSEOTitle
        title={t('page.forget_password_header_text')}
      />
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo"><LogoUrl /></div>
              <div>
                <h1 className="page-heading-center">{t('page.forget_password_header_text')}</h1>
                <div className="page-sub-heading">
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('page.forget_password_sub_header_text')
                      }}></div>
                  </span>
                </div>
              </div>
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
                    <Form.Label className="field-label">
                      {t('page.forget_password_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="email"
                      placeholder={t('page.forget_password_email_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.email && formik.errors.email ? (
                        <div>{t(formik.errors.email)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleCancel}>
                      {t('page.forget_password_login_link_text')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      type="submit"
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.forget_password_submit_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
    </>
  );
};
ForgetPasswordPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { ForgetPasswordPage };