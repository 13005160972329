import React from 'react';
import { Helmet } from 'react-helmet';

const CommonSEOTitle = ({ title, description, ogTitle, ogDescription }) => {
  return (
    <Helmet>
      <title>Technource ERP | {title}</title>
      {/* <meta name="description" content={description} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta name="robots" content="index, follow" /> */}
    </Helmet>
  );
};

export default CommonSEOTitle;
