import client from '@libs/HttpClient';
class HolidaysEtcService {
    static listUserHolidaysEtc(request) {
        const page_no = request.queryKey[1];
        const searchText = request.queryKey[2];
        const selectedType = request.queryKey[3];
        const selectedDateRange = request.queryKey[4];

        return client.get(`/holidays-etc?search=${searchText}&page=${page_no}&selectedType=${selectedType}&selectedDateRange=${selectedDateRange}`);
    }
    static viewHolidaysEtc(params) {
        return client.get(`/holidays-etc/${params}/edit`);
    }
    static updateUserHolidaysEtc(request) {
        return client.put(`/holidays-etc/${request.id}`, request);
    }
    static addUserHolidaysEtc(request) {
        return client.post('/holidays-etc', request);
    }
    static deleteHolidaysEtc(request) {
        return client.delete(`/holidays-etc/${request.id}`, request);
    }
    static useGetHolidayEtcTypeList(request) {
        return client.get(`/holidays-etc/type-list`, request);
    }
    
}
export { HolidaysEtcService };