import { toast } from 'react-toastify';
import { isPossibleNumber } from 'libphonenumber-js';
import moment from 'moment';

// Common Date Fromat
const dateFormatCommon = () => {
  return 'dd-MM-yyyy';
};
// Date Format Add Manually
const dateFormatManualAdd = (e) => {
  if (!/[0-9]/.test(e.key) && e.key === 'Backspace') {
    return false;
  } else {
    e.currentTarget.value = e.currentTarget.value.replace('-', '');
    if (e.currentTarget.value.length > 8) {
      e.preventDefault();
    }
    var numbers = e.currentTarget.value.replace(/[^0-9]/, ''),
      char = { 2: '-', 4: '-' };
    e.currentTarget.value = '';
    for (var i = 0; i < numbers.length; i++) {
      e.currentTarget.value += (char[i] || '') + numbers[i];
    }
    return e.currentTarget.value;
  }
};

// Default Value From Array And Selected Value
const defaultValue = (option, value) => {
  // return option ? option.find((option) => option.value == value) : '';
  if (!option) return '';

  if (Array.isArray(value)) {
    return option.filter((op) => value.includes(op.value));
  } else {
    return option.find((op) => op.value == value) || '';
  }
};

// Default Error Handler
const onDefaultError = (error) => {
  toast.error(error.message);
};
// Phone Number Validation
const phoneNumberValidation = async (countryCode, phoneCode, phoneNumberValue) => {
  return new Promise(function (accepted, rejected) {
    let isValidRequest = phoneNumberValue ? false : true;
    const phoneNumber = '+' + phoneCode + '-' + phoneNumberValue;
    if (phoneNumber) {
      const isValid = isPossibleNumber(phoneNumber, countryCode?.toString());
      if (isValid) {
        isValidRequest = true;
      }
      accepted(isValidRequest);
    }
  });
};

const isMobileOrTablet = () => {
  return window.innerWidth <= 992;
};

// The formatTimeSpent function converts a decimal value of hours (e.g., 2.5) into a human-readable format (e.g., "2h 30m"), enhancing clarity for time tracking and management applications.
function formatTimeSpent(timeSpent) {
  // Convert the input to a number
  const totalHours = parseFloat(timeSpent);

  // Get whole hours and remaining minutes
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);

  // Create the formatted string
  const parts = [];
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  return parts.join(' ');
}

function convertTimeInHoursAndMinutes(h, min) {
  var h = Number(h);
  var min = Number(min);
  // Convert minutes to hours if minutes are 60 or more
  let extraHours = Math.floor(min / 60);
  min = min % 60;

  // Add the extra hours to the given hours
  h += extraHours;

  // Return the formatted string
  var returntime = h != '' && h > 0 ? `${h}h` : '';
  returntime += min != '' && min > 0 ? ` ${min}m` : '';

  return returntime;
}

function getTotalDays(startDate, endDate) {
  // Parse the dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const differenceInTime = end - start;

  // Calculate the difference in days
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  console.log('differenceInDays:', differenceInDays);
  return differenceInDays;
}

function getWeekStartAndEnd(date) {
  const startDate = new Date(date);
  const endDate = new Date(date);

  // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayOfWeek = startDate.getDay();

  // Calculate the start date (Monday of the current week)
  const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  startDate.setDate(startDate.getDate() + mondayOffset);

  // Calculate the end date (Sunday of the current week)
  const sundayOffset = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  endDate.setDate(endDate.getDate() + sundayOffset);

  // Set time to the start of the day
  startDate.setHours(0, 0, 0, 0);
  // Set time to the end of the day
  endDate.setHours(23, 59, 59, 999);

  return {
    start: moment(startDate).format('YYYY-MM-DD'),
    end: moment(endDate).format('YYYY-MM-DD')
  };
}

export {
  defaultValue,
  dateFormatCommon,
  dateFormatManualAdd,
  onDefaultError,
  phoneNumberValidation,
  isMobileOrTablet,
  formatTimeSpent,
  convertTimeInHoursAndMinutes,
  getTotalDays,
  getWeekStartAndEnd
};
