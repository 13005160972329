import { useMutation, useQuery } from 'react-query';
import { UserMasterService } from '@api/UserMasterService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListUsers = (
  [page_no, searchText, status, user_role, user_type, blood_group, tshirt_size, gender, department],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'user-list',
      page_no,
      searchText,
      status,
      user_role,
      user_type,
      blood_group,
      tshirt_size,
      gender,
      department
    ],
    UserMasterService.listUserRoles,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Create
const useAddUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.addUser, {
    onSuccess,
    onError
  });
};
// Details
const useViewUser = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('user-view', () => UserMasterService.viewUser(id), {
    onSuccess,
    onError
  });
};
// Update
const useEditUser = (id, onSuccess, onError = onDefaultError) => {
  return useQuery('user-edit', () => UserMasterService.editUser(id), {
    onSuccess,
    onError
  });
};
// Update User
const useUpdateUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.updateUser, {
    onSuccess,
    onError
  });
};
// Status Update
const useStatusChangeUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.statusChangeUser, {
    onSuccess,
    onError
  });
};
// Remove User
const useDeleteUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.deleteUser, {
    onSuccess,
    onError
  });
};
// Role List
const useGetRoleList = (onSuccess, onError = onDefaultError) => {
  return useQuery('role-list', UserMasterService.getRoleList, {
    onSuccess,
    onError
  });
};
// Route Access List
const useRouteAccessList = (onSuccess, onError = onDefaultError) => {
  return useQuery('route-access-list', UserMasterService.getRouteAccessList, {
    onSuccess,
    onError
  });
};
// Permission
const useCheckUserPermission = ([check_permission], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['check-user-permission', check_permission],
    UserMasterService.checkUserPermission,
    {
      onSuccess,
      onError
    }
  );
};
// Branch List
const useGetBranchList = (onSuccess, onError = onDefaultError) => {
  return useQuery('branch-list', UserMasterService.branchList, {
    onSuccess,
    onError
  });
};
// Department List
const useGetDepartmentList = (onSuccess, onError = onDefaultError) => {
  return useQuery('department-list', UserMasterService.departmentList, {
    onSuccess,
    onError
  });
};
// Update User Address
const useUpdateUserAddress = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.updateUserAddress, {
    onSuccess,
    onError
  });
};
// Update User Contact
const useUpdateUserContact = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.updateUserContact, {
    onSuccess,
    onError
  });
};
// Update User Approver
const useUpdateUserApprover = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserMasterService.updateUserApprover, {
    onSuccess,
    onError
  });
};
export {
  useListUsers,
  useAddUser,
  useViewUser,
  useEditUser,
  useUpdateUser,
  useStatusChangeUser,
  useDeleteUser,
  useGetRoleList,
  useRouteAccessList,
  useCheckUserPermission,
  useGetBranchList,
  useGetDepartmentList,
  useUpdateUserAddress,
  useUpdateUserContact,
  useUpdateUserApprover
};
