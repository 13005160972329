import client from '@libs/HttpClient';
const moment = require('moment');

class UserDepartmentAndDesignationService {
  static listUserDepartmentAndDesignationService(request) {
    const userId = request.id;
    const page_no = request.page_no;
    const searchText = request.searchText;
    const status = request.status;
    const startDate = request.startDate;
    const endDate = request.endDate;
    return client.get(
      `/user/view/${userId}/department-designation?search=${searchText}&page=${page_no}&sprint_status=${status}&start_date=${startDate}&end_date=${endDate}`,
      request
    );
  }
  static deleteUserDepartmentAndDesignationService(userId, id) {
    return client.delete(`/user/view/${userId}/department-designation/${id}`);
  }
  static addUserDepartmentAndDesignationService(request) {
    const userId = request.user_id;
    request.start_date = moment(request.start_date).format('YYYY-MM-DD');
    request.end_date =
      request.end_date != '' && request.end_date != null
        ? moment(request.end_date).format('YYYY-MM-DD')
        : '';
    return client.post(`/user/view/${userId}/department-designation`, request);
  }
  static updateUserDepartmentAndDesignationService(request) {
    const id = request.id;
    const userId = request.user_id;
    request.start_date = moment(request.start_date).format('YYYY-MM-DD');
    request.end_date =
      request.end_date != '' && request.end_date != null
        ? moment(request.end_date).format('YYYY-MM-DD')
        : '';
    return client.put(`/user/view/${userId}/department-designation/${id}`, request);
  }
  static viewUserDepartmentAndDesignationService(userId, id) {
    return client.get(`/user/view/${userId}/department-designation/${id}/edit`);
  }
  // static sprintStatusTypes() {
  //   return client.get('/get-sprint-status-type');
  // }
}
export { UserDepartmentAndDesignationService };
