import { useMutation, useQuery } from 'react-query';
import { TaskHistoryService } from '../api/TaskHistoryService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListTaskHistory = ([project_id,project_task_id,historyPage,searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['task-work-log-list', project_id,project_task_id,historyPage,searchText],
    () => TaskHistoryService.listTaskHistory({ project_id, project_task_id, historyPage, searchText }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

export {
  useListTaskHistory
};