import { useMutation, useQuery } from 'react-query';
import { TaskCommentService } from '../api/TaskCommentService';
import { onDefaultError } from '../helpers/commonHelpers';
// Records
const useListTaskComment = ([project_id, project_task_id,commentPage,searchText], onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['task-comment-list', project_id, project_task_id, commentPage,searchText],
    () => TaskCommentService.listTaskComment({ project_id, project_task_id,commentPage, searchText }),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
// Add
const useAddTaskComment = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskCommentService.addTaskComment, {
    onSuccess,
    onError
  })
}
// Update
const useUpdateTaskComment = (onSuccess, onError = onDefaultError) => {
  return useMutation(TaskCommentService.updateTaskComment, {
    onSuccess,
    onError
  })
}
// Remove
const useDeleteTaskComment = () => {
  const deleteTaskComment = async (projectId, projectTaskId, projectTaskCommentId) => {
    return await TaskCommentService.deleteTaskComment(projectId, projectTaskId, projectTaskCommentId);
  };
  return { deleteTaskComment };
};
export {
  useListTaskComment,
  useAddTaskComment,
  useUpdateTaskComment,
  useDeleteTaskComment
};