import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
const EditUserContact = React.lazy(() => import('./EditUserContact'));
const ViewUserContact = React.lazy(() => import('./ViewUserContact'));
const UserContactRoutes = ({ t }) => {
  return [
    <Route key="edit_user_contact" exact path="/edit/:id" element={<EditUserContact t={t} />} />,
    <Route
      key="view_user_contact"
      exact
      path="/view/:id/contact"
      element={<ViewUserContact t={t} />}
    />
  ];
};
UserContactRoutes.propTypes = {
  t: PropTypes.func
};
export default UserContactRoutes;
