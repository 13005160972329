// React Dependencies
import { useState, useMemo, React, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// Component
import { Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { TNTable, TNButton } from '@common/components';
import { ProjectNavigation } from '../Navigation/ProjectNavigation';
// API
import { useRouteAccessList, useListTeamMember, useDeleteTeamMember } from '@hooks';
// Alert
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
const ListTeamMemberPage = ({ t }) => {
  // Navigate
  const navigate = useNavigate();
  // Project ID
  let { id } = useParams();
  // Member Type Option
  const memberTypeOption = [
    { value: '1', label: 'Project Manager / Project Coordinator' },
    { value: '2', label: 'Team Member' },
    { value: '3', label: 'Client' },
    { value: '4', label: 'QA' }
  ];
  // States
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminTeamPost !== undefined && localStorage.adminTeamPost !== ''
      ? JSON.parse(localStorage.adminTeamPost).currentPage
      : 1
  );
  const [paginationData, setPaginationData] = useState(null);
  const [data, setData] = useState([]);
  const [routeName, setRouteNames] = useState([]);
  const [searchText, setSearchText] = useState(
    localStorage.adminTeamPost !== undefined && localStorage.adminTeamPost !== ''
      ? JSON.parse(localStorage.adminTeamPost).searchText
      : ''
  );
  const [memberType, setMemberType] = useState(
    localStorage.adminTeamPost !== undefined && localStorage.adminTeamPost !== ''
      ? JSON.parse(localStorage.adminTeamPost).memberType
      : ''
  );
  // Route
  useRouteAccessList((res) => {
    setRouteNames(res.data.route_name);
  });
  const shouldShowRoute = (route) => {
    if (routeName) {
      return routeName.includes(route);
    }
  };
  // On Load Set Filters
  useEffect(() => {
    let adminTeamPost = {
      id: id,
      searchText: searchText,
      currentPage: currentPage,
      memberType: memberType
    };
    localStorage.adminTeamPost = JSON.stringify(adminTeamPost);
  }, [id, currentPage, searchText, memberType]);
  // List Data
  const { refetch } = useListTeamMember(
    [id, currentPage, searchText, memberType],
    (res) => {
      setData(res.data.team_member_list);
      setPaginationData(res.data.pagination);
    },
    (error) => {
      if (error?.data?.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      } else {
        toast.error(error.message);
      }
    }
  );
  const handleSearch = (search_text) => {
    setSearchText(search_text.target.value);
  };
  const handleClearFilter = () => {
    setSearchText('');
    setMemberType('');
    setCurrentPage(1);
    localStorage.removeItem('adminTeamPost');
  };
  // Delete Team Member
  const { deleteTeamMember } = useDeleteTeamMember();
  const handleDeleteClick = (id, teamMemberId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.delete_team_member_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={async () => {
                  try {
                    const response = await deleteTeamMember(id, teamMemberId);
                    if (response && response.message) {
                      toast.success(response.message);
                    }
                    onClose();
                    refetch();
                  } catch (error) {
                    toast.error(error);
                  }
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };
  // Table Action Column
  const actionColumn = {
    Header: `${t('page.action_column')}`,
    accessor: 'team_member_id',
    Cell: ({ value: teamMemberId }) => {
      return (
        <div className="d-inline-flex">
          {shouldShowRoute('projects.team.destroy') && (
            <TNButton
              className="table-delete-button"
              onClick={() => handleDeleteClick(id, teamMemberId)}>
              {t('page.action_button_text_delete')}
            </TNButton>
          )}
        </div>
      );
    },
    disableSortBy: true
  };
  let showActionColumn = shouldShowRoute('projects.team.destroy');
  // Table Columns
  const columnsjson = [
    {
      Header: `${t('page.project_team_member_name')}`,
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <p dangerouslySetInnerHTML={{ __html: row.original.name }}></p>;
      }
    },
    {
      Header: `${t('page.project_team_member_type')}`,
      accessor: 'type',
      disableSortBy: true
    }
  ];
  if (showActionColumn) {
    columnsjson.push(actionColumn);
  }
  const columns = useMemo(() => columnsjson, [routeName]);
  if (paginationData === null) {
    return null;
  }
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
    localStorage.removeItem('adminTeamPost');
  };
  return (
    <>
      <CommonSEOTitle title={t('page.project_team_member_tab')} />
      <ProjectNavigation t={t} id={id} activeLabel="Team Member" />
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group className="filter-field-space" style={{ width: '300px' }}>
              <Select
                placeholder={t('page.project_member_type_placeholder')}
                options={memberTypeOption}
                value={memberTypeOption.filter(
                  (memberTypeValue) => memberTypeValue.value === memberType
                )}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setMemberType(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder="Search"
                onKeyUp={handleSearch}
                onChange={handleSearch}
                className="filter-column form-field"
                value={searchText}
              />
            </Form.Group>
            <Button
              type="button"
              onClick={handleClearFilter}
              className="secondary-remove-button"
              disabled={searchText || memberType ? false : true}>
              {t('page.cancel_search_button')}
            </Button>
          </div>
        </Col>
      </Row>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        pageIndexGet={currentPage - 1}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
      />
    </>
  );
};
ListTeamMemberPage.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func,
  row: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.any
};
export default ListTeamMemberPage;
