import React, { useRef, useState, forwardRef, useEffect, useCallback, memo, useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { loggedUser } from '../../admin/store/features/authSlice';
import { Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import axios from 'axios';
import MagicUrl from 'quill-magic-url';
import { MentionBlot, Mention } from 'quill-mention';
import 'quill-mention/dist/quill.mention.css'; // Mention dropdown styles
import { confirmAlert } from 'react-confirm-alert';
import { TNButton } from './TNButton';

import QuillBetterTable from 'quill-better-table';
import 'quill-better-table/src/assets/quill-better-table.scss'; // Import the Better Table styles

Quill.register('modules/magicUrl', MagicUrl);
Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
Quill.register(
  {
    'modules/better-table': QuillBetterTable
  },
  true
);
const fontSizeArr = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px'
];

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
const CustomReactQuillEditor = memo(
  forwardRef(
    (
      {
        t,
        value,
        onChange,
        placeholder = 'Start typing...',
        onFocus,
        onBlur,
        taggingUsers = [],
        fileUploadDir,
        ...props
      },
      ref
    ) => {
      const checkLoggedInUser = useSelector(loggedUser);
      const accessToken = checkLoggedInUser.user.access_token;
      const [editorValue, setEditorValue] = useState(value || ''); // Initialize editor state
      const reactQuillRef = useRef(null);
      const mentionUsersRef = useRef(taggingUsers);
      const [rows, setRows] = useState(5); // Default 5 rows
      const [cols, setCols] = useState(5); // Default 5 columns
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
      useEffect(() => {
        setEditorValue(value || ''); // Sync editorValue when value prop changes
      }, [value]);

      useEffect(() => {
        if (taggingUsers == null) {
          (async () => {
            try {
              const url = 'api/tagging-users';
              const response = await axios.post(url, {}, axiosConfig);
              const apiUsers = response.data;
              mentionUsersRef.current = apiUsers.data;
            } catch (error) {
              console.error('Error fetching users:', error);
              mentionUsersRef.current = [];
            }
          })();
        } else {
          mentionUsersRef.current = taggingUsers;
        }
      }, [taggingUsers]);

      // Handle editor changes
      const handleEditorChange = (content) => {
        setEditorValue(content);
        if (onChange) onChange(content); // Notify parent of changes
      };

      const tableHandler = useCallback(() => {
        let crow = '';
        let ccol = '';

        confirmAlert({
          customUI: ({ onClose }) => (
            <div className="alert-box">
              <div className="alert-popup" style={{ padding: '15px' }}>
                <Row>
                  <Col md={4}>
                    <Form>
                      <Form.Group controlId="formRows">
                        <Form.Control
                          type="number"
                          name="rows"
                          max={100}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              crow = Number(e.target.value);
                            }
                          }}
                          placeholder={t('page.quill_table_number_of_rows')}
                        />
                      </Form.Group>
                    </Form>
                  </Col>

                  <Col md={4}>
                    <Form>
                      <Form.Group controlId="formCols">
                        <Form.Control
                          type="number"
                          name="cols"
                          max={100}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              ccol = Number(e.target.value);
                            }
                          }}
                          placeholder={t('page.quill_table_number_of_columns')}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={1}>
                    <TNButton
                      className="btn btn-primary"
                      onClick={() => {
                        const quill = reactQuillRef.current.getEditor();
                        const tableModule = quill.getModule('better-table');
                        tableModule.insertTable(
                          Number(crow) > 0 ? crow : rows,
                          Number(ccol) > 0 ? ccol : cols
                        );
                        onClose();
                      }}>
                      {t('page.quill_table_popup_yes_button')}
                    </TNButton>
                  </Col>
                  <Col md={2}>
                    <TNButton
                      className="btn btn-danger"
                      onClick={() => {
                        onClose();
                      }}>
                      {t('page.quill_table_popup_no_button')}
                    </TNButton>
                  </Col>
                </Row>
              </div>
            </div>
          )
        });
      }, [rows, cols]); // Ensure this is aware of rows and cols state

      // Image handler for the toolbar
      const imageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '*/*');
        input.click();
        input.onchange = async () => {
          if (input.files && input.files[0]) {
            const file = input.files[0];
            const data = new FormData();
            data.append('upload', file);
            data.append('subdir', fileUploadDir);

            try {
              const url = 'api/common/ckeditor/file-upload';
              const response = await axios.post(url, data, axiosConfig);
              const fileUrl = response.data;

              const quill = reactQuillRef.current;
              if (quill) {
                const range = quill.getEditorSelection();
                if (range) {
                  const fileType = file.type.split('/')[0];
                  if (fileType === 'image') {
                    quill.getEditor().insertEmbed(range.index, 'image', fileUrl);
                  } else if (fileType === 'video') {
                    quill.getEditor().insertEmbed(range.index, 'video', fileUrl);
                  } else {
                    quill.getEditor().insertText(range.index, fileUrl, 'link', fileUrl);
                  }
                }
              }
            } catch (error) {
              toast.error(error?.response?.statusText ?? 'something went wrong');
            }
          }
        };
      }, []);
      const getProfileImageURL = (userId, profileImage) => {
        if (profileImage) {
          let imageURL = `${process.env.MIX_AWS_URL}user_profile/${userId}/${profileImage}`;
          return imageURL;
        } else {
          return false;
        }
      };
      const getImageName = (firstName, lastName) => {
        let imageName = 'US';
        if (firstName) {
          imageName = firstName.substring(0, 1);
        }
        if (lastName) {
          imageName += lastName.substring(0, 1);
        }
        return imageName;
      };

      const modules = useMemo(
        () => ({
          toolbar: {
            container: [
              [{ font: [] }],
              [{ size: fontSizeArr }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ align: [] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ list: 'ordered' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              ['link', 'image', 'video'],
              ['blockquote', 'code-block'],
              ['clean'],
              ['table']
            ],
            handlers: {
              image: imageHandler,
              table: tableHandler
            }
          },
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            source: async function (searchTerm, renderList) {
              const matchedPeople = mentionUsersRef.current.filter((person) =>
                person.value.toLowerCase().includes(searchTerm.toLowerCase())
              );
              renderList(matchedPeople);
            },
            renderItem: function (item, searchTerm) {
              const span = document.createElement('span');
              span.classList.add('mention-item');
              const div = document.createElement('div');
              div.classList.add(
                item.profile_image ? 'profileImageWithImage' : 'profileImageWithoutImage'
              );
              div.classList.add('profile-img-mention-user');
              div.setAttribute('data-toggle', 'tooltip');
              div.setAttribute('data-placement', 'top');
              div.setAttribute('title', item.full_name);
              if (item.profile_image && item.profile_image !== '') {
                const img = document.createElement('img');
                img.src = getProfileImageURL(item.id, item.profile_image);
                img.alt = item.first_name + ' ' + item.last_name;
                div.appendChild(img);
              } else {
                const initials = getImageName(item.first_name, item.last_name);
                div.textContent = initials;
              }
              span.appendChild(div);
              const text = document.createElement('span');
              text.innerHTML = item.value;
              span.appendChild(text);
              return span;
            }
          },
          'better-table': {
            operationMenu: {
              items: {
                unmergeCells: {
                  text: 'Another unmerge cells name'
                }
              }
            }
          },
          magicUrl: true
        }),
        []
      );

      return (
        <div>
          <ReactQuill
            theme="snow"
            ref={reactQuillRef}
            value={editorValue}
            onChange={handleEditorChange}
            modules={modules}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            {...props}
          />
        </div>
      );
    }
  )
);

export default memo(CustomReactQuillEditor);
