// React Dependencies
import React, { useState, useEffect } from 'react';
// Common Dependencies
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// Component
import { Container, Form, InputGroup, FormControl } from 'react-bootstrap';
import { TNButton } from '@common/components';
// Validation
import { useFormik } from 'formik';
import validationSchema from './LoginValidation';
// Alert
import { toast } from 'react-toastify';
// API
import { useLogin, useGetSettingDataAlways } from '@hooks';
import { loginSuccess } from '../../../store/features/authSlice';
import { addSetting } from '../../../store/features/settingSlice';
// Layout
import { PublicLayout, LogoUrl } from '@adminlayout';
// Auth Functions
import { setAuthToken, setAuthType } from '@libs/HttpClient';
import CommonSEOTitle from '../../../../helpers/CommonSEOTitle';
const LoginPage = ({ t }) => {
  // Navigate And Dispatch
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // On Load Add Class To Body
  useEffect(() => { document.body.classList.add('bg-box'); }, []);
  // Layout Data Get On Load
  useGetSettingDataAlways(({ data: setting }) => {
    const dataStore = {
      dashboard_logo: setting.common_header_logo,
      front_logo: setting.common_login_logo,
      email_logo: setting?.common_email_header_logo,
      favicon_icon: setting?.common_favicon_icon,
      seo_title: setting.seo_title,
      seo_description: setting.seo_description,
      setting_get: true
    };
    dispatch(addSetting(dataStore));
  });
  // Submit Details For Login
  const {mutate: doLogin, isLoading } = useLogin(
    (response) => {
      const dataStore = {
        userData: response.data,
        isLoggedIn: true,
        accessToken: response.data.access_token
      };
      dispatch(loginSuccess(dataStore));
      setTimeout(function () {
        window.addEventListener('storage', (e) => { e.isLogout = 0;});
        setAuthToken(response.data.access_token);
        setAuthType(response.data.user_auth_type);
        navigate('/dashboard');
      }, 1500);
      toast.success(response.message);
    },
    (error) => {
      toast.error(error.message);
      window.scrollTo(0, 0);
    }
  );
  // Show Hide Password State
  const [isRevealPwd, setIsRevealPwd] = useState();
  // Form Validation And Submit
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      doLogin(values);
    }
  });
  return (
    <>
     <CommonSEOTitle
        title={t('page.login_header_text')}
      />
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo"><LogoUrl /></div>
              <div className="login-heading">
                <h1 className="page-heading-center">{t('page.login_header_text')}</h1>
              </div>
              <div>
                {/* Login Form */}
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="field-label">{t('page.login_email_label')}</Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="email"
                      placeholder={t('page.login_email_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.email && formik.errors.email ? (
                        <div>{t(formik.errors.email)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div>
                    <Form.Label className="field-label field-label-top">
                      {t('page.login_password_label')}
                    </Form.Label>
                    <InputGroup className="form-group-field">
                      <FormControl
                        className={
                          '' +
                          (formik.touched.password && formik.errors.password
                            ? 'form-field-error'
                            : formik.touched.password && !formik.errors.password
                            ? 'form-field-success'
                            : '')
                        }
                        name="password"
                        placeholder={t('page.login_password_placeholder')}
                        autoComplete="off"
                        type={isRevealPwd ? 'text' : 'password'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <InputGroup.Text
                        className={
                          '' +
                          (formik.touched.password && formik.errors.password
                            ? 'form-field-error'
                            : formik.touched.password && !formik.errors.password
                            ? 'form-field-success'
                            : '')
                        }>
                        <FontAwesomeIcon
                          onClick={() => setIsRevealPwd((prevState) => !prevState)}
                          icon={isRevealPwd ? faEye : faEyeSlash}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="form-field-error-text">
                      {formik.touched.password && formik.errors.password ? (
                        <div>{t(formik.errors.password)}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="link-right">
                    <Link to="/forget-password" className="text-black">
                      {t('page.login_forget_link_text')}
                    </Link>
                  </div>
                  <div className="primary-button">
                    <TNButton
                      loading={isLoading}
                      type="submit"
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.login_submit_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
    </>
  );
};
LoginPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { LoginPage };